import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { LoginRefreshRequest, LoginRequest, LoginResponse, ResetPasswordRequest, ResetPasswordResponse } from "@premier/models";

export class AuthService {
    public static async Login(request: LoginRequest): Promise<LoginResponse> {
        const [status, response] = await fetchApi<LoginResponse>(ApiSettings, {
            method: "POST",
            path: "/api/Auth/Login",
            requiresAuth: false,
            body: request,
            mediaType: "application/json"
        });

        return response;
    }

    public static async resetPassword(request: ResetPasswordRequest): Promise<ResetPasswordResponse> {
        const [status, response] = await fetchApi<ResetPasswordResponse>(ApiSettings, {
            method: "POST",
            path: "/api/Auth/resetPassword",
            requiresAuth: false,
            body: request,
            mediaType: "application/json"
        });

        return response;
    }

    public static async refreshToken(request: LoginRefreshRequest): Promise<LoginResponse> {
        const [status, response] = await fetchApi<LoginResponse>(ApiSettings, {
            method: "POST",
            path: "/api/Auth/RefreshToken",
            requiresAuth: false,
            body: request,
            mediaType: "application/json"
        });

        return response;
    }
}