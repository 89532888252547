import ImageOutlined from '@mui/icons-material/ImageOutlined';
import HideImageOutlined from '@mui/icons-material/HideImageOutlined';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRowModel, GridRowOrderChangeParams, GridValueGetterParams, MuiEvent, useGridApiRef } from '@mui/x-data-grid-pro';
import { BlueprintDTO, ClientDTO, QuestionCategoryDTO } from '@premier/models';
import { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { ClientsService, QuestionCategoriesService } from '../../../lib/api/api';
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import ArchiveAction from '../ArchiveAction';
import DeleteAction from '../DeleteAction';
import PreviewImageAction from '../PreviewImageAction';
import BlueprintUploader from '../LazyCells/BlueprintUploader';

interface IProps {
    surveyId: string;
    categories: QuestionCategoryDTO[];
    refresh: () => Promise<void>;
}

const updateRow = async (newRow: GridRowModel<QuestionCategoryDTO>) => {
    const resp = await QuestionCategoriesService.updateQuestionCategory(newRow.id, {...newRow, Questions: []});
    return resp.questionCategory!;
}

const updateOrder = async (clientId: string, ids: string[]) => {
    const resp = await QuestionCategoriesService.updateOrder({order: ids});
    return resp;
}

const QuestionCategoryTable: FunctionComponent<IProps> = ({ categories, surveyId, refresh }) => {
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(categories);

    useEffect(() => setRows(categories), [categories]);

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setLoading(true);

        const selectedRows = apiRef.current.getSelectedRows();
        const hasDragged = selectedRows.has(params.row.id);
        if (hasDragged) {
            const selections = [...rows].filter((r) => selectedRows.has(r.id));
            const rowsClone = [...rows].filter((r) => !selections.map((v) => v.id).includes(r.id));

            rowsClone.splice(params.targetIndex, 0, ...(selections as QuestionCategoryDTO[]));

            const newRows = await updateOrder(surveyId, rowsClone.map((r) => r.id));

            apiRef.current.setSelectionModel([]);

            setRows(newRows);
        } else {
            const rowsClone = [...rows];
            const row = rowsClone.splice(params.oldIndex, 1)[0];
            rowsClone.splice(params.targetIndex, 0, row);

            const newRows = await updateOrder(surveyId, rowsClone.map((r) => r.id));

            setRows(newRows);
        }
        setLoading(false);
    };

    const deleteBlueprint = async (id: string) => {
        await BlueprintsService.deleteBlueprint(id);
        refresh();
    }

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            editable: true
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 0.5,
            editable: true
        }
    ];

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={columns}
                pageSize={25}
                experimentalFeatures={{ newEditingApi: true }}
                rowReordering
                processRowUpdate={updateRow}
                onRowOrderChange={handleRowOrderChange}
            />
        </Box>
    );
};

export default QuestionCategoryTable;
