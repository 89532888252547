import { Button, ClickAwayListener, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Popper } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ClientDTO } from "@premier/models";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ClientsTable from "../../../components/tables/ClientTable";
import { ClientsService } from "../../../lib/api/api";
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';

interface SearchedClient {
    id: string;
    displayName: string;
    hits: number;
}

const Clients: FunctionComponent = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [clients, setClients] = useState<ClientDTO[]>([]);

    const [searchTerm, setSearchTerm] = useState('');
    const anchorEl = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const getClients = async () => {
            const clients = await ClientsService.getClients();

            if (clients.clients) {
                setClients(clients.clients);

                if (params.clientId) {
                    const c = clients.clients.find((c) => c.id === params.clientId);

                    if (c)
                        setSearchTerm(c.clientNumber + ' - ' + c.name + ' - ' + c.address + ', ' + c.city + ', ' + c.state + ' ' + c.zip)
                }
            }
        };

        getClients();
    }, []);

    const handleFocus = () => {
        setOpen(true);
    }

    const handleBlur = () => {
        setOpen(false);
    }

    const handleClientClick = (client: SearchedClient) => {
        setOpen(false);
        setSearchTerm(client.displayName);
        navigate(`${client.id}/edit`);
    }

    const splitSearch = searchTerm.trim().split(' ');

    const searchedClients = (clients.map((c) => {
        const displayName = c.clientNumber + ' - ' + c.name + ' - ' + c.address + ', ' + c.city + ', ' + c.state + ' ' + c.zip;

        const hits = splitSearch.map((v) => displayName.toLowerCase().includes(v.toLowerCase())).filter((v) => v === true).length;

        return {
            id: c.id,
            displayName: displayName,
            hits: hits
        };
    }) as SearchedClient[]).filter((c) => c.hits > 0 || splitSearch.length === 0).sort((a, z) => z.hits - a.hits);

    return (
        <Stack margin={2} flex={1}>
            <ClickAwayListener onClickAway={handleBlur}>
                <Stack>
                    <Stack spacing={2} direction='row'>
                        <TextField
                            ref={anchorEl}
                            id="client-search"
                            label="Search"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setOpen(true);
                            }}
                            onFocus={handleFocus}
                            sx={{ flex: 1 }}
                            autoComplete='off'
                        />
                        <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={() => {
                            setSearchTerm('');
                            navigate(`new/edit`);
                        }}>
                            New
                        </Button>
                    </Stack>
                    {open &&
                        <Popper
                            placement="bottom"
                            anchorEl={anchorEl.current}
                            open={open}
                            sx={{ width: '100%', zIndex: 9999 }}
                        >
                            <Paper
                                sx={{
                                    margin: 2,
                                    marginTop: 1,
                                    padding: 1
                                }}
                            >
                                <Stack maxHeight={200} overflow='auto'>
                                    <List sx={{ bgcolor: 'background.paper' }}>
                                        {searchedClients.map((value) => (
                                            <ListItemButton
                                                key={value.id + value.displayName}
                                                disableGutters
                                                sx={{ padding: 1 }}
                                                onClick={() => handleClientClick(value)}
                                            >
                                                <ListItemText primary={value.displayName} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Stack>
                            </Paper>
                        </Popper>
                    }
                </Stack>
            </ClickAwayListener>
            <Outlet />
        </Stack>
    );
}

export default Clients;