import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGridPro, GridColDef, GridRowModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { AccountDTO, BuildingDTO, ClientDTO, GroupDTO, RoleDTO } from '@premier/models';
import { FunctionComponent } from 'react';
import { AccountsService } from '../../../lib/api/api';
import EditAction from '../EditAction';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import LockResetIcon from '@mui/icons-material/LockReset';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AsyncAction from '../AsyncAction';
import Stack from '@mui/material/Stack';
import CustomAction from '../CustomAction';

interface IProps {
    accounts: AccountDTO[];
    roles: RoleDTO[];
    groups: GroupDTO[];
    refresh: () => Promise<void>;
}

const updateAccount = async (newRow: GridRowModel<AccountDTO>) => {
    const resp = await AccountsService.updateAccount(newRow.id!, {
        email: newRow.email,
        firstName: newRow.firstName,
        lastName: newRow.lastName,
        roles: newRow.Roles?.map((v) => v.id!),
        groups: newRow.Groups?.map((v) => v.id!)
    });
    return resp.account!;
}

const AccountsTable: FunctionComponent<IProps> = ({ accounts, roles, groups, refresh }) => {
    const columns: GridColDef[] = [
        { field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) => 
            <Stack direction={'row'}>
                <AsyncAction f={AccountsService.resetPassword} text='Reset Password' id={params.row.id} icon={<LockResetIcon />} />
                <CustomAction f={async (id) => {
                    await AccountsService.toggleDisable(id);
                    refresh();
                }} isToggled={params.row.disabled} text={`${params.row.disabled ? 'Enable' : 'Disable'} Account`} id={params.row.id} toggledIcon={<PersonOffOutlinedIcon />} icon={<PersonOutlinedIcon />} />
            </Stack>        
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 0.25,
            editable: true
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 0.25,
            editable: true
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 0.5,
            editable: true
        },
        {
            field: 'roles',
            headerName: 'Roles',
            flex: 0.5,
            renderCell: (params) =>
                <Autocomplete
                    multiple
                    fullWidth
                    id="roles-autocomplete"
                    limitTags={1}
                    options={roles}
                    onChange={async (e, r) => {
                        await updateAccount({
                            ...params.row,
                            Roles: r
                        });
                        refresh();
                    }}
                    getOptionLabel={(option) => option?.name ?? 'N'}
                    value={[...((params.row.Roles as RoleDTO[]).map((v) => roles.find((r) => r.id === v.id)).filter((r) => !!r))]}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                        />
                    )}
                />
        },
        {
            field: 'groups',
            headerName: 'Groups',
            flex: 0.5,
            renderCell: (params) =>
                <Autocomplete
                    multiple
                    fullWidth
                    id="groups-autocomplete"
                    limitTags={1}
                    options={groups}
                    onChange={async (e, g) => {
                        await updateAccount({
                            ...params.row,
                            Groups: g
                        });
                        refresh();
                    }}
                    getOptionLabel={(option) => option?.name ?? 'N'}
                    value={[...((params.row.Groups as GroupDTO[]).map((v) => groups.find((r) => r.id === v.id)).filter((r) => !!r))]}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                        />
                    )}
                />
        }
    ];

    return (
        <Box flex={1}>
            <DataGridPro
                getRowHeight={() => 'auto'}
                rows={accounts}
                columns={columns}
                pagination={true}
                processRowUpdate={updateAccount}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
            />
        </Box>
    );
};

export default AccountsTable;
