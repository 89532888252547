import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { BlueprintDTO, ClientDTO, JobDTO } from "@premier/models";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BlueprintsTable from '../../../components/tables/BlueprintTable';
import ClientsTable from "../../../components/tables/ClientTable";
import { useStores } from '../../../hooks/useStores';
import { ClientsService } from "../../../lib/api/api";
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import { JobsService } from '../../../lib/api/api/services/JobsService';
import { PDFToImage } from '../../../utils/PDF/PDFToImage';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { type } from 'os';

interface FileArray {
    name: string;
    type: string;
    buffer: ArrayBuffer;
}

const Blueprints: FunctionComponent = () => {
    const { rootStore } = useStores();
    const params = useParams();
    const navigate = useNavigate();

    const inputEl = useRef<HTMLInputElement | null>(null);

    const [jobTypes, setJobTypes] = useState<JobDTO[]>([]);
    const [blueprints, setBlueprints] = useState<BlueprintDTO[]>([]);
    const [currentJobType, setCurrentJobType] = useState('');

    const clientId = params.clientId as string;

    const getJobTypes = async () => {
        const jobTypes = await JobsService.getJobs();

        if (jobTypes.jobs) {
            setJobTypes(jobTypes.jobs);
            setCurrentJobType(jobTypes.jobs[0].id!)
        }

        getBlueprints();
    };

    const getBlueprints = async () => {
        const blueprints = await ClientsService.getBlueprints(clientId);

        if (blueprints.blueprints) setBlueprints(blueprints.blueprints);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setCurrentJobType(event.target.value as string);
    };

    const uploadBlueprints = (files: FileArray[]) => {
        for (const file of files) {
            rootStore.wsService?.uploadBlueprint(clientId, currentJobType, file, 0, (success) => {
                getBlueprints();
            });
        }
    };

    const uploadBlueprint = async (file: FileArray, order: number) => await rootStore.wsService?.uploadBlueprintAsync(clientId, currentJobType, file, order);

    const onChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
        const uploadAsync = async () => {
            const fileArray: FileArray[] = [];

            const files = e.target.files;

            const toastId = toast(`Selected ${files?.length} files...`, { autoClose: false, closeButton: false, closeOnClick: false, isLoading: true });

            if (!files) return;

            const numberOfFiles = files.length;

            for (let i = 0; i < numberOfFiles; i++) {
                const file = files.item(i);

                if (!file || (!file.type.includes('image') && !file.type.includes('application/pdf'))) continue;

                if (file.type.includes('pdf')) {
                    // PDF to image
                    const images = await (new PDFToImage(file, toastId)).getImages();

                    if (images)
                        images.forEach((i, index) => {
                            const lastIndex = file.name.lastIndexOf('.');
                            const ext = file.name.substring(lastIndex + 1);
                            const fileName = file.name.substring(0, lastIndex);
                            fileArray.push({
                                name: fileName + ' - Page ' + index + '.png',
                                type: 'image/png',
                                buffer: Buffer.from(i, 'base64')
                            });
                        });
                } else {

                    const fileBuffer = await file.arrayBuffer();

                    fileArray.push({
                        name: file.name,
                        type: file.type,
                        buffer: fileBuffer
                    });
                }
            }

            fileArray.sort((a, z) => a.name.localeCompare(z.name));

            let failed: string[] = [];
            for (let i = 0; i < fileArray.length; i++) {
                const file = fileArray[i];

                toast.update(toastId, {
                    type: toast.TYPE.INFO,
                    render: `Uploading blueprint ${i + 1} of ${fileArray.length}...`,
                    progress: i /fileArray.length
                });

                const thisToastId = toast(`Uploading blueprint '${file.name}'...`, {
                    type: toast.TYPE.INFO,
                    closeButton: false,
                    autoClose: false,
                    closeOnClick: false
                })
                const status = await uploadBlueprint(file, i + 1);

                if (!status) failed.push(file.name);

                toast.update(thisToastId, {
                    type: status ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
                    render: status ? 'Uploaded successfully.' : `Upload failed for ${file.name}.`,
                    autoClose: status ? 1500 : 5000,
                    closeButton: null,
                    closeOnClick: true
                });
            }

            toast.update(toastId, {
                type: failed.length > 0 ? toast.TYPE.WARNING : toast.TYPE.SUCCESS,
                render: `Blueprints uploaded. ${failed.length > 0 ? `${failed.length} uploads failed.` : ''}`,
                closeButton: null,
                closeOnClick: true,
                progress: undefined,
                isLoading: false,
                autoClose: 5000,
                pauseOnHover: false
            });

            getBlueprints();
        };

        if (e.target.files && e.target.files.length > 0)
            uploadAsync();
    };

    useEffect(() => {
        getJobTypes();
    }, [clientId]);

    const filteredBlueprints = blueprints.filter((b) => b.jobId === currentJobType);

    return (
        <Stack width='100%' flex={1} spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="jobtype-select-label">Job Type</InputLabel>
                <Select
                    labelId="jobtype-select-label"
                    id="jobtype-select"
                    value={currentJobType}
                    label="Job Type"
                    onChange={handleChange}
                >
                    {jobTypes.map((j) => <MenuItem key={j.id} value={j.id}>{j.name}</MenuItem>)}
                </Select>
            </FormControl>
            <input ref={inputEl} accept="image/*, application/pdf" type='file' multiple style={{ display: 'none' }} onChange={onChangeWrapper} />
            <Button variant="outlined" startIcon={<CloudUploadOutlinedIcon />} onClick={() => inputEl.current?.click()}>
                Upload Blueprints
            </Button>
            <BlueprintsTable blueprints={filteredBlueprints} clientId={clientId} refresh={getBlueprints} />
        </Stack>
    );
}

export default Blueprints;