import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRowModel, GridRowOrderChangeParams, GridToolbar, GridValueGetterParams, MuiEvent, useGridApiRef } from '@mui/x-data-grid-pro';
import { BlueprintDTO, ClientDTO, InspectionDTO, InspectionIssueDTO } from '@premier/models';
import { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { ClientsService } from '../../../lib/api/api';
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import { InspectionsService } from '../../../lib/api/api/services/InspectionsService';
import ArchiveAction from '../ArchiveAction';
import DeleteAction from '../DeleteAction';
import PreviewImageAction from '../PreviewImageAction';
import { DateTime } from 'luxon';
import CopyAction from '../CopyAction';
import { toast } from 'react-toastify';
import LinkAction from '../LinkAction';
import CustomAction from '../CustomAction';
import rootStore from '../../../stores/RootStore';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

interface IProps {
    issues: InspectionIssueDTO[];
    refresh: () => Promise<void>;
}

const TodaysIssuesTable: FunctionComponent<IProps> = ({ issues, refresh }) => {
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(issues);

    useEffect(() => setRows(issues), [issues]);

    const columns: GridColDef<InspectionIssueDTO>[] = [
        {
            field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) =>
                <Stack direction='row'>
                    <LinkAction text='View Job' link={`https://inspection.premierfire.app/InspectionHome?id=${params.row.inspectionId}`} />
                </Stack>
        },
        {
            field: 'client',
            headerName: 'Client',
            flex: 1,
            valueGetter: (params) => params.row.Inspection?.Client?.name ?? 'N/A'
        },
        {
            field: 'jobNumber',
            headerName: 'Job Number',
            flex: 1,
            valueGetter: (params) => `${params.row.Inspection?.Client?.clientNumber}${String(params.row.Inspection?.inspectionNumber).padStart(4, '0')}`
        },
        {
            field: 'modifiedBy',
            headerName: 'Modified By',
            flex: 1,
            valueGetter: (params) => `${params.row.ModifiedByTech?.firstName ?? 'N/A'} ${params.row.ModifiedByTech?.lastName ?? 'N/A'}`
        },
        {
            field: 'completedAt',
            headerName: 'Completed At',
            flex: 0.5,
            valueGetter: (params) => DateTime.fromMillis(params.row.inspectionCompleted! as unknown as number).toLocaleString(DateTime.TIME_SIMPLE)
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            flex: 1,
            valueGetter: (params) => `${params.row.CreatedByTech?.firstName ?? 'N/A'} ${params.row.CreatedByTech?.lastName ?? 'N/A'}`
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 0.5,
            valueGetter: (params) => DateTime.fromISO(params.row.createdAt! as unknown as string).toLocaleString(DateTime.DATETIME_FULL)
        },
    ];

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <DataGridPro
                components={{
                    Toolbar: GridToolbar
                }}
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={columns}
                pageSize={25}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
            />
        </Box>
    );
};

export default TodaysIssuesTable;
