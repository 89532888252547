import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { BlueprintDTO, ClientDTO, JobDTO, QuestionCategoryDTO, QuestionDTO } from "@premier/models";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BlueprintsTable from '../../../components/tables/BlueprintTable';
import ClientsTable from "../../../components/tables/ClientTable";
import { useStores } from '../../../hooks/useStores';
import { ClientsService, InspectionTypesService, QuestionCategoriesService, QuestionsService } from "../../../lib/api/api";
import * as uuid from 'uuid';
import QuestionsTable from '../../../components/tables/QuestionTable';
import AnswersModal from '../../../components/modals/AnswersModal';
import DeficienciesModal from '../../../components/modals/DeficienciesModal';

const Questions: FunctionComponent = () => {
    const { rootStore } = useStores();
    const params = useParams();
    const navigate = useNavigate();

    const [categories, setCategories] = useState<QuestionCategoryDTO[]>([]);
    const [questions, setQuestions] = useState<QuestionDTO[]>([]);
    const [currentCategory, setCurrentCategory] = useState('');
    const [modalId, setModalId] = useState('');
    const [modalName, setModalName] = useState('');
    const [showAnswerModal, setShowAnswerModal] = useState(false);
    const [showDefModal, setShowDefModal] = useState(false);

    const surveyId = params.surveyId as string;

    const getCategories = async () => {
        const resp = await InspectionTypesService.getCategories(surveyId);

        if (resp) {
            setCategories(resp);
            setCurrentCategory(resp[0].id!);
        }

        getQuestions(resp[0].id!);
    };

    const showAnswersModal = (id: string, name: string) => {
        setModalId(id);
        setModalName(name);
        setShowAnswerModal(true);
    };

    const showDefsModal = (id: string, name: string) => {
        setModalId(id);
        setModalName(name);
        setShowDefModal(true);
    };

    const getQuestions = async (id?: string) => {
        const resp = await QuestionCategoriesService.getQuestions(id ?? currentCategory);

        if (resp) setQuestions(resp);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setCurrentCategory(event.target.value as string);
    };

    useEffect(() => {
        if (uuid.validate(surveyId))
            getCategories();
    }, [surveyId]);

    useEffect(() => {
        if (uuid.validate(currentCategory))
            getQuestions();
    }, [currentCategory]);

    const createNew = async () => {
        await QuestionsService.createQuestion({
            id: '',
            name: 'New Question',
            description: '',
            selectionType: 'SINGLE_SELECT',
            required: false,
            copyOnNew: false,
            order: questions.length > 0 ? questions[questions.length - 1].order + 1 : 1,
            reportVerbage: 'New Question',
            oldId: 0,
            nfpaCodes: [],
            hidden: false,
            showSpecSheet: false,
            triggerRequireAll: false,
            triggerQuestion: [""],
            triggerAnswer: [""],
            triggerGroups: [""],
            defaultAnswer: '',
            isLabelDeficiency: false,
            relabelType: 0,
            questionCategoryId: currentCategory,
            Answers: [],
            Deficiencies: [],
            QuestionExtraInfos: []
        });

        getQuestions();
    }

    return (
        <Stack width='100%' flex={1} spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="category-select-label">Question Category</InputLabel>
                <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={currentCategory}
                    label="Question Category"
                    onChange={handleChange}
                >
                    {categories.map((j) => <MenuItem key={j.id} value={j.id}>{j.name}</MenuItem>)}
                </Select>
            </FormControl>
            <Button variant="outlined" onClick={createNew}>
                New Question
            </Button>
            <QuestionsTable showDefsModal={showDefsModal} showAnswersModal={showAnswersModal} questions={questions} surveyId={surveyId} refresh={getQuestions} />
            <AnswersModal close={() => setShowAnswerModal(false)} isOpen={showAnswerModal} questionId={modalId} questionName={modalName} />
            {showDefModal && <DeficienciesModal close={() => setShowDefModal(false)} isOpen={showDefModal} questionId={modalId} questionName={modalName} />}
        </Stack>
    );
}

export default Questions;