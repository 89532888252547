import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { ClientResponse, ClientRequest, ClientsResponse, JobsResponse, InspectionsResponse, ClientDTO, BuildingsResponse, BlueprintsResponse } from "@premier/models";

export class ClientsService {
    public static async getClients(): Promise<ClientsResponse> {
        const [status, response] = await fetchApi<ClientsResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Clients/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getJobs(id: string): Promise<JobsResponse> {
        const [status, response] = await fetchApi<JobsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Clients/${id}/Jobs`,
            requiresAuth: true
        });

        return response;
    }

    public static async getBuildings(id: string): Promise<BuildingsResponse> {
        const [status, response] = await fetchApi<BuildingsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Clients/${id}/Buildings`,
            requiresAuth: true
        });

        return response;
    }

    public static async getBlueprints(id: string): Promise<BlueprintsResponse> {
        const [status, response] = await fetchApi<BlueprintsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Clients/${id}/Blueprints`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateBlueprintsOrder(id: string, blueprintIds: string[]): Promise<BlueprintsResponse> {
        const [status, response] = await fetchApi<BlueprintsResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Clients/${id}/Blueprints/UpdateOrder`,
            requiresAuth: true,
            body: blueprintIds
        });

        return response;
    }

    public static async getInspections(id: string): Promise<InspectionsResponse> {
        const [status, response] = await fetchApi<InspectionsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Clients/${id}/Inspections`,
            requiresAuth: true
        });

        return response;
    }

    public static async getClient(id: string): Promise<ClientResponse> {
        const [status, response] = await fetchApi<ClientResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Clients/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteClient(id: string): Promise<ClientResponse> {
        const [status, response] = await fetchApi<ClientResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Clients/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateClient(id: string, data: ClientDTO): Promise<ClientResponse> {
        const [status, response] = await fetchApi<ClientResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Clients/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createClient(data: ClientDTO): Promise<ClientResponse> {
        const [status, response] = await fetchApi<ClientResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Clients/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}