import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { BlueprintDTO, PinDTO } from '@premier/models';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { InspectionsService } from '../../../lib/api/api/services/InspectionsService';
import CustomAction from '../CustomAction';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import PreviewPinModal from '../../modals/PreviewPinModal';
import ArchiveAction from '../ArchiveAction';
import { useStores } from '../../../hooks/useStores';
import { toast } from 'react-toastify';

interface IProps {
    pins: PinDTO[];
    blueprints: BlueprintDTO[];
    loading: boolean;
    jobId: string;
}

const PinsTable: FunctionComponent<IProps> = ({ pins, jobId, blueprints, loading }) => {
    const { rootStore } = useStores();
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState(pins);

    const [blueprintId, setBlueprintId] = useState('');
    const [pinId, setPinId] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => setRows(pins), [pins]);

    const columns: GridColDef<PinDTO>[] = [
        {
            field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) =>
                <Stack direction='row'>
                    <CustomAction icon={<AspectRatioOutlinedIcon />} text='View on Blueprint' f={() => {
                        setBlueprintId(params.row.blueprintId);
                        setPinId(params.row.id);
                        setShow(true);
                    }} id={params.row.id} />
                    <ArchiveAction isArchived={params.row.hidden} onClick={() => {
                        // TODO reimplement
                        rootStore.wsService?.addHiddenPinAsync(jobId, params.row.id);
                    }} />
                </Stack>
        },
        {
            field: 'blueprint',
            headerName: 'LSP',
            flex: 0.5,
            valueGetter: (params) => blueprints.find((v) => v.id === params.row.blueprintId)?.name ?? 'N/A'
        },
        {
            field: 'floor',
            headerName: 'Floor',
            flex: 0.5,
            valueGetter: (params) => blueprints.find((v) => v.id === params.row.blueprintId)?.floorNumber
        },
        {
            field: 'x',
            headerName: 'X',
            flex: 0.5
        },
        {
            field: 'y',
            headerName: 'Y',
            flex: 0.5
        }
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={columns}
                pageSize={25}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
            />
            {show &&
                <PreviewPinModal isOpen={show} close={() => setShow(false)} blueprint={blueprints.find((b) => b.id === blueprintId)} pin={pins.find((p) => p.id === pinId)} />
            }
        </Box>
    );
};

export default PinsTable;
