import { FunctionComponent } from "react";
import Stack from "@mui/material/Stack";
import { IconButton, Tooltip } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface IProps {
    onClick: () => void;
}

const EditAnswersAction: FunctionComponent<IProps> = ({onClick}) => {
    return (
        <Stack width='100%' alignItems='center'>
            <Tooltip title='Edit Answers'>
                <IconButton onClick={onClick}>
                    <EditOutlinedIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export default EditAnswersAction;