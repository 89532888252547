import { BlueprintDTO, ClientDTO } from "@premier/models";
import { FunctionComponent, useState } from "react";
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import Stack from "@mui/material/Stack";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PreviewImageModal from "../../modals/PreviewImageModal";

interface IProps {
    row: BlueprintDTO;
}

const PreviewImageAction: FunctionComponent<IProps> = ({ row }) => {
    const [show, setShow] = useState(false);
    return (
        <Stack width='100%' alignItems='center'>
            <Tooltip title="Preview Image">
                <IconButton onClick={() => setShow(true)}>
                    <AspectRatioOutlinedIcon />
                </IconButton>
            </Tooltip>
            {show &&
                <PreviewImageModal isOpen={show} close={() => setShow(false)} imageId={row.image} name={row.name} rotation={row.rotation} />
            }
        </Stack>
    );
};

export default PreviewImageAction;