import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { AccountsResponse, BuildingsResponse, FullInspectionResponse, InspectionDuplicateResponse, InspectionIssueRequest, InspectionIssueResponse, InspectionRequest, InspectionResponse, InspectionsResponse, InspectionStatusResponse, PartsResponse } from "@premier/models";

export class InspectionsService {
    public static async getInspections(): Promise<InspectionsResponse> {
        const [status, response] = await fetchApi<InspectionsResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Inspections/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getInspection(id: string): Promise<InspectionResponse> {
        const [status, response] = await fetchApi<InspectionResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Inspections/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async getBuildingsForJob(id: string): Promise<BuildingsResponse> {
        const [status, response] = await fetchApi<BuildingsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Inspections/${id}/Buildings`,
            requiresAuth: true
        });

        return response;
    }

    public static async getInspectionsByClientJob(clientId: string, jobTypeId: string): Promise<InspectionsResponse> {
        const [status, response] = await fetchApi<InspectionsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Inspections/Client/${clientId}/JobType/${jobTypeId}`,
            requiresAuth: true
        });

        return response;
    }

    public static async createNewInspection(clientId: string, jobTypeId: string): Promise<InspectionsResponse> {
        const [status, response] = await fetchApi<InspectionsResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Inspections/Client/${clientId}/JobType/${jobTypeId}/new`,
            requiresAuth: true
        });

        return response;
    }

    public static async getStatus(id: string): Promise<InspectionStatusResponse> {
        const [status, response] = await fetchApi<InspectionStatusResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Inspections/${id}/status`,
            requiresAuth: true
        });

        return response;
    }

    public static async duplicate(id: string): Promise<InspectionDuplicateResponse> {
        const [status, response] = await fetchApi<InspectionDuplicateResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Inspections/${id}/duplicate`,
            requiresAuth: true
        });

        return response;
    }

    public static async getParts(id: string): Promise<PartsResponse> {
        const [status, response] = await fetchApi<PartsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Inspections/${id}/parts`,
            requiresAuth: true
        });

        return response;
    }

    public static async getCompleteInspection(id: string): Promise<FullInspectionResponse> {
        const [status, response] = await fetchApi<FullInspectionResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Inspections/${id}/Complete`,
            requiresAuth: true
        });

        return response;
    }

    public static async getTechs(id: string): Promise<AccountsResponse> {
        const [status, response] = await fetchApi<AccountsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Inspections/${id}/Techs`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteInspection(id: string): Promise<InspectionResponse> {
        const [status, response] = await fetchApi<InspectionResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Inspections/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateInspection(id: string, data: InspectionRequest): Promise<InspectionResponse> {
        const [status, response] = await fetchApi<InspectionResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Inspections/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async addTech(id: string, techId: string): Promise<InspectionResponse> {
        const [status, response] = await fetchApi<InspectionResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Inspections/${id}/Tech`,
            requiresAuth: true,
            body: { techId }
        });

        return response;
    }

    public static async updateInspectionIssue(id: string, data: InspectionIssueRequest): Promise<InspectionResponse> {
        const [status, response] = await fetchApi<InspectionResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Inspections/${id}/Issue`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createInspection(data: InspectionRequest): Promise<InspectionResponse> {
        const [status, response] = await fetchApi<InspectionResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Inspections/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createInspectionIssue(inspectionId: string, data: InspectionIssueRequest): Promise<InspectionIssueResponse> {
        const [status, response] = await fetchApi<InspectionIssueResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Inspections/${inspectionId}/Issue`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}