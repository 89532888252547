import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { AccountsResponse, BuildingsResponse, FullInspectionResponse, InspectionDuplicateResponse, InspectionIssueDTO, InspectionIssueRequest, InspectionIssueResponse, InspectionRequest, InspectionResponse, InspectionsResponse, InspectionStatusResponse, PartsResponse } from "@premier/models";

export class IssuesService {
    public static async getTodaysIssues(): Promise<InspectionIssueDTO[]> {
        const [status, response] = await fetchApi<InspectionIssueDTO[]>(ApiSettings, {
            method: "GET",
            path: "/api/InspectionIssues/today",
            requiresAuth: true
        });

        return response;
    }
}