import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { BuildingDTO, QuestionCategoryGroupDTO } from '@premier/models';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as uuid from 'uuid';
import { InspectionTypesService } from '../../../lib/api/api';
import { BuildingsService } from '../../../lib/api/api/services/BuildingsService';
import { QuestionCategoryGroupsService } from '../../../lib/api/api/services/QuestionCategoryGroupsService';

interface IProps {
    groupId: string;
    isOpen: boolean;
    close: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const QuestionGroupModal: FunctionComponent<IProps> = ({ groupId, isOpen, close }) => {
    const params = useParams();

    const [group, setGroup] = useState<QuestionCategoryGroupDTO>();

    const surveyId = params.surveyId as string;

    useEffect(() => {
        const getGroup = async () => {
            console.log('eher')
            const resp = await QuestionCategoryGroupsService.getQuestionCategoryGroup(groupId);

            if (resp) setGroup(resp);
        };

        if (uuid.validate(groupId)) {
            getGroup();
        } else {
            setGroup({
                id: '',
                name: '',
                order: 0,
                defaultGroup: false,
                specSheet: false,
                tenantId: '',
                inspectionTypeId: surveyId,
                QuestionCategories: []
            });
        }
    }, [groupId]);

    const saveGroup = async () => {
        if (uuid.validate(groupId)) {
            const save = await QuestionCategoryGroupsService.updateQuestionCategoryGroup(groupId, group!);

            if (!!!save) {
                console.log('failed to save');
            }

            close();
        } else {
            const save = await QuestionCategoryGroupsService.createQuestionCategoryGroup(group!);

            if (!!!save) {
                console.log('failed to save');
            }

            close();
        }
    }

    const setField = (field: string, v: string) => {
        setGroup({
            ...group!,
            [field]: v
        });
    }

    return (
        <Modal
            open={isOpen}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {group &&
                    <Stack spacing={2} justifyContent='center'>
                        <TextField
                            id="group-name"
                            label="Name"
                            variant="outlined"
                            value={group.name}
                            onChange={(e) => setField('name', e.target.value)}
                        />
                        <Button onClick={saveGroup}>
                            Save
                        </Button>
                    </Stack>
                }
                {!group &&
                    <>Loading...</>
                }
            </Box>
        </Modal>
    );
};

export default QuestionGroupModal;