import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ClientDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../../hooks/useStores";

interface IProps {
    name: string;
}

const GeneralReport: FunctionComponent<IProps> = ({ name }) => {
    const { rootStore } = useStores();
    const params = useParams();

    const jobId = params.jobId as string;

    return (
        <Stack flex={1} alignItems={'center'}>
            <Button fullWidth onClick={() => rootStore.wsService?.downloadReportAsync(name, jobId)}>
                Download Report
            </Button>
        </Stack>
    );
}

export default GeneralReport;