import { AccountDTO } from '@premier/models';
import { makeAutoObservable, runInAction } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import AuthenticationService from '../lib/api/AuthenticationService';
import decode from 'jwt-decode';
import rootStore from './RootStore';

export class AuthenticationStore {
    companyCode: string | null = null;

    user: AccountDTO | null = null;

    token: string | null = null;

    refreshToken: string | null = null;

    loggedIn: boolean = false;

    constructor() {
        makeAutoObservable(this);

        makePersistable(this, {
            name: 'AuthStore',
            properties: ['token', 'companyCode', 'user', 'refreshToken'],
            storage: localStorage
        });
    }

    get isHydrated() {
        return isHydrated(this);
    }

    logout = () => {
        runInAction(() => {
            //rootStore.wsService?.destroy();
            //rootStore.wsService = undefined;
            this.token = null;
            this.user = null;
            this.refreshToken = "";
            this.loggedIn = false;
        });
    };

    getMyId = async () => {
        if (this.user === null)
            await this.updateUser();

        return this.user?.id;
    };

    updateUser = async () => {
        const user = await AuthenticationService.updateUser();

        runInAction(() => {
            if (user.me)
                this.user = user.me;
            else
                this.user = null;
        });
    };

    isAdmin = () => {
        if (this.user?.Roles === undefined)
            return false;

        for (let role of this.user.Roles) {
            if (role.scopes.includes('Admin')) return true;
        }
    };

    loginAsync = async (email: string, password: string) => {
        
        const [user, token, refreshToken] = await AuthenticationService.login(
            email,
            password
        );
        runInAction(() => {
            this.token = token;
            this.refreshToken = refreshToken;
            this.user = user;
            this.loggedIn = token !== null;
        });

        return token !== null;
    };

    // TODO finish implementing
    extendTokenAsync = async () => {
        if (!this.refreshToken) {
            this.logout();
            return;
        }

        const decodedRefreshToken: any = decode(this.refreshToken);

        if (decodedRefreshToken.exp < Date.now() / 1000) {
            console.warn('Refresh token expired');
            this.logout();
            return;
        }

        const [user, token, refreshToken] = await AuthenticationService.refreshTokenAsync(this.refreshToken);

        runInAction(() => {
            this.token = token;
            this.refreshToken = refreshToken;
            this.user = user;
            this.loggedIn = token !== null;
        });
    };
}
