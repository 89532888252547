import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ClientDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ClientsTable from "../../../components/tables/ClientTable";
import { ClientsService } from "../../../lib/api/api";

const Job: FunctionComponent = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const jobId = params.jobId as string;

    const lastLocation = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    return (
            <Stack marginTop={2} direction={'row'} flex={1} spacing={2}>
                <List sx={{ bgcolor: 'background.paper' }}>
                <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'inspections'}
                        onClick={() => navigate('inspections')}
                    >
                        <ListItemText primary='Inspections' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'incompletes'}
                        onClick={() => navigate('incompletes')}
                    >
                        <ListItemText primary='Incomplete Inspections' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'hidden'}
                        onClick={() => navigate('hidden')}
                    >
                        <ListItemText primary='Hidden Pins' />
                    </ListItemButton>
                </List>
                <Outlet key={jobId} />
            </Stack>
    );
}

export default Job;