import { Button, ClickAwayListener, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Popper } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ClientDTO, InspectionTypeDTO } from "@premier/models";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ClientsTable from "../../../components/tables/ClientTable";
import { ClientsService, InspectionTypesService } from "../../../lib/api/api";
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';

interface SearchObject {
    id: string;
    displayName: string;
    hits: number;
}

const Surveys: FunctionComponent = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [surveys, setSurveys] = useState<InspectionTypeDTO[]>([]);

    const [searchTerm, setSearchTerm] = useState('');
    const anchorEl = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const getSurveys = async () => {
            const surveys = await InspectionTypesService.getInspectionTypes();

            if (surveys.inspectionTypes) {
                setSurveys(surveys.inspectionTypes);

                if (params.clientId) {
                    const s = surveys.inspectionTypes.find((c) => c.id === params.surveyId);

                    if (s)
                        setSearchTerm(s.name);
                }
            }
        };

        getSurveys();
    }, []);

    const handleFocus = () => {
        setOpen(true);
    }

    const handleBlur = () => {
        setOpen(false);
    }

    const handleClick = (survey: SearchObject) => {
        setOpen(false);
        setSearchTerm(survey.displayName);
        navigate(`${survey.id}/edit`);
    }

    const splitSearch = searchTerm.trim().split(' ');

    const searchedClients = (surveys.map((s) => {
        const hits = splitSearch.map((v) => s.name.toLowerCase().includes(v.toLowerCase())).filter((v) => v === true).length;

        return {
            id: s.id,
            displayName: s.name,
            hits: hits
        };
    }) as SearchObject[]).filter((c) => c.hits > 0 || splitSearch.length === 0).sort((a, z) => z.hits - a.hits);

    return (
        <Stack margin={2} flex={1}>
            <ClickAwayListener onClickAway={handleBlur}>
                <Stack>
                    <Stack spacing={2} direction='row'>
                        <TextField
                            ref={anchorEl}
                            id="survey-search"
                            label="Search"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onFocus={handleFocus}
                            sx={{ flex: 1 }}
                            autoComplete='off'
                        />
                        <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={() => {
                            setSearchTerm('');
                            navigate(`new/edit`);
                        }}>
                            New
                        </Button>
                    </Stack>
                    {open &&
                        <Popper
                            placement="bottom"
                            anchorEl={anchorEl.current}
                            open={open}
                            sx={{ width: '100%', zIndex: 9999 }}
                        >
                            <Paper
                                sx={{
                                    margin: 2,
                                    marginTop: 1,
                                    padding: 1
                                }}
                            >
                                <Stack maxHeight={200} overflow='auto'>
                                    <List sx={{ bgcolor: 'background.paper' }}>
                                        {searchedClients.map((value) => (
                                            <ListItemButton
                                                key={value.id + value.displayName}
                                                disableGutters
                                                sx={{ padding: 1 }}
                                                onClick={() => handleClick(value)}
                                            >
                                                <ListItemText primary={value.displayName} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Stack>
                            </Paper>
                        </Popper>
                    }
                </Stack>
            </ClickAwayListener>
            <Outlet />
        </Stack>
    );
}

export default Surveys;