import rootStore from '../../../stores/RootStore';
import { BlueprintDTO, Contract, FileUpload, ImageDTO, InspectionDTO, InspectionIssueDTO, InspectionIssueRequest, InspectionSignatures, PinDTO, ProjectScope, SocketActiveInspectionRequest, SocketClientInfoDto, ToggledDef } from '@premier/models';
import { runInAction } from 'mobx';
import decode from 'jwt-decode';
import * as uuid from 'uuid';
import { ApiUtils } from './core/Utils';
import { io, Socket } from 'socket.io-client';
import saveAs from 'file-saver';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';

export class WebSocketService {
    private _socket: Socket;

    constructor() {
        console.log('Creating web socket...');
        this._socket = io(ApiUtils.getApiWs());

        this._socket.on('connected', () => {
            console.log('Connect: ', this._socket.id);
            this._socket.emit('register', { token: rootStore.authStore.token, refreshToken: rootStore.authStore.refreshToken });
        });

        this._socket.on('disconnect', (reason) => {
            console.log('Socket disconnected with reason: ', reason);
        });

        this._socket.on('refreshToken', (data: { token: string, refreshToken: string }) => {
            console.log('Refreshed tokens over WS.');
            runInAction(() => {
                rootStore.authStore.token = data.token;
                rootStore.authStore.refreshToken = data.refreshToken;
            });
        });

        this._socket.on('userInfoRequest', (requestSocketId: string) => {
            const userInfo: SocketClientInfoDto = {
                name: `${rootStore.authStore.user?.firstName ?? 'N/A'} ${rootStore.authStore.user?.lastName ?? 'N/A'}`,
                status: 'Portal',
                app: 'Portal',
                sId: this._socket.id,
            };
            this._socket.emit('userInfoResponse', requestSocketId, userInfo);
        });
    }

    setInspectionChannel(data: SocketActiveInspectionRequest) {
        this._socket.emit('setInspectionChannel', data);
    }

    leaveInspectionChannel() {
        this._socket.emit('leaveInspectionChannel');
    }

    uploadBlueprint(clientId: string, jobTypeId: string, file: FileUpload, order: number, callback: (success: boolean) => void) {
        this._socket.off('uploadBlueprintFinished');
        this._socket.on('uploadBlueprintFinished', callback);
        this._socket.emit('uploadBlueprint', clientId, jobTypeId, file);
    }

    uploadBlueprintAsync(clientId: string, jobTypeId: string, file: FileUpload, order: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._socket.off('uploadBlueprintFinished');
            this._socket.on('uploadBlueprintFinished', (success: boolean) => resolve(success));
            this._socket.emit('uploadBlueprint', clientId, jobTypeId, file, order);
        });
    }

    getConnectedClients(): Promise<SocketClientInfoDto[]> {
        return new Promise((resolve, reject) => {
            console.log(this._socket.id);
            this._socket.off('getConnectedClientsFinished');
            this._socket.on('getConnectedClientsFinished', (clients: SocketClientInfoDto[]) => resolve(clients));
            this._socket.emit('getConnectedClients');
        });
    }

    retrieveUserInfo(socketId: string): Promise<SocketClientInfoDto> {
        return new Promise((resolve, reject) => {
            this._socket.off('retrieveUserInfoFinished');
            this._socket.on('retrieveUserInfoFinished', (client: SocketClientInfoDto) => resolve(client));
            this._socket.emit('retrieveUserInfo', socketId);
        });
    }

    retrieveJobDatabase(socketId: string, inspectionId?: string): Promise<{ db64: string, wal64: string }> {
        return new Promise((resolve, reject) => {
            this._socket.off('retrieveJobDatabaseFinished');
            this._socket.on('retrieveJobDatabaseFinished', (db64: string, wal64: string) => resolve({ db64, wal64 }));
            this._socket.emit('retrieveJobDatabase', socketId, inspectionId);
        });
    }

    async repairDoorCountAsync(jobId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                const toastId = toast('Repairing door count...', { autoClose: false, closeButton: false, isLoading: true, type: toast.TYPE.INFO });
                this._socket.off('repairDoorCountFinished');
                this._socket.on('repairDoorCountFinished', (success: boolean, fixed: number) => {
                    toast.update(toastId, {
                        render: success ? `${fixed} door count pins repaired.` : 'Failed to repair door count',
                        isLoading: false,
                        autoClose: 5000,
                        closeButton: null,
                        type: success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR
                    });
                    resolve(success);
                });

                this._socket.emit('repairDoorCount', jobId);
            } catch (reason) {
                console.log(reason);
                reject(reason);
            }
        });
    }

    async getBlueprintsAsync(clientId: string): Promise<BlueprintDTO[] | null> {
        return new Promise((resolve, reject) => {
            this._socket.off('getBlueprintsFinished');
            this._socket.on('getBlueprintsFinished', (blueprints?: BlueprintDTO[]) => {
                if (blueprints) resolve(blueprints);
                resolve(null);
            });
            this._socket.emit('getBlueprints', clientId);
        });
    }

    async addHiddenPinAsync(inspId: string, pinId: string) {
        return new Promise((resolve) => {
            this._socket.off('uploadHiddenPinFinished');
            this._socket.on('uploadHiddenPinFinished', resolve);
            this._socket.emit('uploadHiddenPin', inspId, pinId);
        });
    }

    async toggleHiddenIssue(id: string) {
        return new Promise((resolve) => {
            this._socket.off('toggleHideIssueFinished');
            this._socket.on('toggleHideIssueFinished', resolve);
            this._socket.emit('toggleHideIssue', id);
        });
    }

    async getIssuesAsync(jobId: string): Promise<InspectionIssueDTO[] | null> {
        return new Promise((resolve, reject) => {
            const issues: InspectionIssueDTO[] = [];
            this._socket.off('getIssuesFinished');
            this._socket.off('getIssuesPart');
            this._socket.on('getIssuesPart', (issue: InspectionIssueDTO) => {
                issues.push(issue);
            });
            this._socket.on('getIssuesFinished', (numOfIssues?: number) => {
                if (numOfIssues === issues.length) resolve(issues);
                resolve(null);
            });
            this._socket.emit('getIssues', jobId);
        });
    }

    async getJobAsync(jobId: string): Promise<InspectionDTO | null> {
        return new Promise((resolve, reject) => {
            this._socket.off('getJobFinished');
            this._socket.on('getJobFinished', (job?: InspectionDTO) => {
                if (job) resolve(job);
                resolve(null);
            });
            this._socket.emit('getJob', jobId);
        });
    }

    async getPinsAsync(jobId: string): Promise<PinDTO[] | null> {
        return new Promise((resolve, reject) => {
            this._socket.off('getPinsFinished');
            this._socket.on('getPinsFinished', (pins?: PinDTO[]) => {
                if (pins) resolve(pins);
                resolve(null);
            });
            this._socket.emit('getPins', jobId);
        });
    }

    async downloadReportAsync(reportType: string, jobId: string, options?: object): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const toastId = toast('Downloading report...', { autoClose: false, type: toast.TYPE.INFO, isLoading: true });
            this._socket.off('DownloadSpreadsheet');

            const callbackWrapper = async (data: { name: string, type: string, wbData: string }) => {
                const download = async (data: { name: string, type: string, wbData: string }) => {
                    const blob = new Blob([Buffer.from(data.wbData, 'base64')], { type: data.type });
                    saveAs(blob, data.name);
                    return true;
                };

                const ret = data.wbData !== null && data.wbData.length > 0 ? await download(data) : false;

                toast.update(toastId, {
                    render: ret ? 'Report downloaded.' : 'Report generation failed.',
                    autoClose: 5000,
                    isLoading: false,
                    type: ret ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
                    pauseOnHover: false
                });

                resolve(ret);
            };

            this._socket.on('DownloadSpreadsheet', callbackWrapper);

            this._socket.emit('DownloadReport', reportType, jobId, options);
        });
    }

    isConnected() {
        return this._socket.connected;
    }

    destroy() {
        this._socket.disconnect();
    }
}
