import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { Button, TextField } from '@mui/material';
import { useStores } from '../../../hooks/useStores';
import { Container } from '@mui/system';

interface IProps {
}

const LoginForm: FunctionComponent<IProps> = ({ }) => {
    const { rootStore } = useStores();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = async () => {
        //setIsLoggingIn(true);

        const success = await rootStore.authStore.loginAsync(email, password);

        if (!success) {
            //handleFailedLogin();
        }
    };

    return (
        <Stack padding={5} width='100vw' alignItems='center'>
            <Stack flexDirection={'column'} spacing={2}>
                <Stack alignItems='center' direction='row'>
                    <TextField fullWidth id="email" label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
                </Stack>
                <Stack direction='row'>
                    <TextField fullWidth id="password" label="Password" variant="outlined" type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                </Stack>
                <Button onClick={login}>
                    Login
                </Button>
            </Stack>
        </Stack>
    );
}

export default LoginForm;
