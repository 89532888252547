import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { BlueprintDTO, ClientDTO, CompleteInspectionDto, InspectionDTO, InspectionIssueDTO, JobDTO, PinDTO } from "@premier/models";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BlueprintsTable from '../../../components/tables/BlueprintTable';
import ClientsTable from "../../../components/tables/ClientTable";
import { useStores } from '../../../hooks/useStores';
import { ClientsService } from "../../../lib/api/api";
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import { JobsService } from '../../../lib/api/api/services/JobsService';
import { PDFToImage } from '../../../utils/PDF/PDFToImage';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { type } from 'os';
import { InspectionsService } from '../../../lib/api/api/services/InspectionsService';
import PinsTable from '../../../components/tables/PinsTable';
import IssuesTable from '../../../components/tables/IssuesTable';

interface FileArray {
    name: string;
    type: string;
    buffer: ArrayBuffer;
}

const JobInspections: FunctionComponent = () => {
    const { rootStore } = useStores();
    const params = useParams();

    const [pins, setPins] = useState<PinDTO[]>([]);
    const [issues, setIssues] = useState<InspectionIssueDTO[]>([]);
    const [job, setJob] = useState<InspectionDTO>();
    const [blueprints, setBlueprints] = useState<BlueprintDTO[]>([]);
    const [loading, setLoading] = useState(true);

    const jobId = params.jobId as string;

    const getInspection = async () => {
        setLoading(true);
        const resp = await rootStore.wsService?.getJobAsync(jobId);

        if (resp) {
            const blueprints = await rootStore.wsService?.getBlueprintsAsync(resp.clientId);
            const pins = await rootStore.wsService?.getPinsAsync(jobId);
            const issues = await rootStore.wsService?.getIssuesAsync(jobId);

            if (blueprints && pins && issues) {
                setBlueprints(blueprints);
                setPins(pins);
                setIssues(issues);
                setJob(resp);
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        getInspection();
    }, [jobId]);

    return (
        <Stack width='100%' flex={1} spacing={2}>
            <IssuesTable refresh={getInspection} jobId={job?.id ?? ''} loading={loading} issues={issues} pins={pins} blueprints={blueprints ?? []} />
        </Stack>
    );
}

export default JobInspections;