import { FunctionComponent } from "react";
import Stack from "@mui/material/Stack";
import { IconButton, Tooltip } from "@mui/material";
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';

interface IProps {
    f?: (id: string) => void;
    id: string;
    text: string;
    isToggled?: boolean;
    toggledIcon?: JSX.Element;
    icon?: JSX.Element;
}

const CustomAction: FunctionComponent<IProps> = ({ f, id, text, isToggled = false, toggledIcon, icon = <BuildOutlinedIcon /> }) => {
    return (
        <Stack width='100%' alignItems='center'>
            <Tooltip title={text}>
                <IconButton onClick={() => {
                    if (f)
                        f(id);
                }}>
                    {isToggled ? toggledIcon ?? icon  : icon}
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export default CustomAction;