//@ts-ignore
import * as PDFJS from 'pdfjs-dist/webpack';
import { Id, toast } from 'react-toastify';
import { CloseButton } from 'react-toastify/dist/components';

export class PDFToImage {
    constructor(public pdfFile: File, public toastId?: Id) {

    }

    private _readFileData(file: File): Promise<string | ArrayBuffer | null | undefined> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target?.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };

    private async _convertPdfToImages(file: File) {
        const images: string[] = [];
        const data = await this._readFileData(file);
        if (!data) return;
        const pdf = await PDFJS.getDocument(data).promise;
        const canvas = document.createElement("canvas");
        for (let i = 0; i < pdf.numPages; i++) {
            if (this.toastId)
                toast.update(this.toastId, {
                    render: `Converting PDF '${this.pdfFile.name}' to images - Page ${i + 1} of ${pdf.numPages}...`,
                    type: toast.TYPE.INFO,
                    autoClose: false
                });
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            if (!context) return;
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport }).promise;
            images.push(canvas.toDataURL().split(',')[1]);
        }
        canvas.remove();

        if (this.toastId)
            toast.update(this.toastId, {
                render: `Finished converting PDF '${this.pdfFile.name}'.`,
                type: toast.TYPE.SUCCESS
            });

        return images;
    }

    async getImages() {
        if (this.toastId)
            toast.update(this.toastId, {
                render: `Converting PDF '${this.pdfFile.name}' to images...`,
                type: toast.TYPE.INFO,
                autoClose: false,
                closeButton: false
            });
        return await this._convertPdfToImages(this.pdfFile);
    }
}