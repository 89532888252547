import { BlueprintDTO, ClientDTO } from "@premier/models";
import { FunctionComponent, useState } from "react";
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import Stack from "@mui/material/Stack";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IProps {
    text: string;
    link: string;
    internal?: boolean;
    icon?: JSX.Element;
}

const LinkAction: FunctionComponent<IProps> = ({ text, link, icon, internal = false }) => {
    const navigate = useNavigate();

    return (
        <Stack width='100%' alignItems='center'>
            <Tooltip title={text}>
                {!internal ?
                    <a href={link} target='_blank'>
                        <IconButton>
                            {icon ? icon : <PreviewOutlinedIcon />}
                        </IconButton>
                    </a>
                    :
                    <IconButton onClick={() => navigate(link)}>
                        {icon ? icon : <PreviewOutlinedIcon />}
                    </IconButton>
                }
            </Tooltip>
        </Stack>
    );
};

export default LinkAction;