import { AccountDTO, LoginRequest } from '@premier/models';
import { runInAction } from 'mobx';
import IAuthentication from './interfaces/IAuthentication';
import { ApiSettings } from './api/core/OpenApiSettings';
import { AccountsService } from './api/services/AccountsService';
import { AuthService } from './api/services/AuthService';

class AuthenticationService implements IAuthentication {
    login = async (
        email: string,
        password: string
    ): Promise<[AccountDTO | null, string | null, string | null]> => {
        const request: LoginRequest = {
            email: email,
            password: password
        };
        
        const response = await AuthService.Login(request);

        if (response && response.token) {
            ApiSettings.token = response.token;

            return [response.user, response.token, response.refreshToken];
        }

        return [null, null, null];
    };

    refreshTokenAsync = async (refreshToken: string): Promise<[AccountDTO | null, string | null, string| null]> => {
        const response = await AuthService.refreshToken({
            refreshToken: refreshToken
        });

        if (response && response.token) {
            ApiSettings.token = response.token;

            return [response.user, response.token, response.refreshToken];
        }

        return [null, null, null];
    };

    updateUser = async () => {
        return await AccountsService.getMe();
    };
}

export default new AuthenticationService();
