import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AnswerDTO, DeficiencyDTO, DeficiencyTypeDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as uuid from 'uuid';
import { AnswersService, QuestionsService } from "../../../lib/api/api";
import { DeficienciesService } from "../../../lib/api/api/services/DeficiencyService";
import AnswersTable from "../../tables/AnswerTable";
import DeficienciesTable from "../../tables/DeficienciesTable";

interface IProps {
    close: () => void;
    isOpen: boolean;
    questionName: string;
    questionId: string;
}

const DeficienciesModal: FunctionComponent<IProps> = ({ questionId, questionName, isOpen, close }) => {
    const [deficiencies, setDeficiencies] = useState<DeficiencyDTO[]>([]);
    const [qDeficiencies, setQDeficiencies] = useState<string[]>([]);
    const [types, setTypes] = useState<DeficiencyTypeDTO[]>([]);
    const [selectionId, setSelectionId] = useState('');
    const params = useParams();

    const surveyId = params.surveyId as string;

    const getDeficiencies = async () => {
        const resp = await DeficienciesService.getDeficiencies();
        const qresp = await QuestionsService.getDeficiencies(questionId);

        if (qresp) setQDeficiencies(qresp.map((d) => d.id));

        if (resp.deficiencies) {
            setDeficiencies(resp.deficiencies);
        }
    };

    const getDeficiencyTypes = async () => {
        const resp = await DeficienciesService.getDeficiencyTypes();

        if (resp) {
            setTypes(resp);
        }

        getDeficiencies();
    };

    useEffect(() => {
        if (uuid.validate(questionId))
            getDeficiencyTypes();
    }, [questionId]);

    const createNew = async () => {
        const resp = await DeficienciesService.createDeficiency({
            id: '',
            displayText: 'New deficiency',
            description: '',
            order: 0,
            triggerDeficiency: false,
            hidden: false,
            resolves: false,
            closes: false,
            requiresPhase2: false,
            requiresPhase3: false,
            requiresRelabel: false,
            isRelabelOption: false,
            isClientAddressOption: false,
            labelApplied: false,
            relabelType: null,
            replacementType: null,
            oldId: null,
            tenantId: '',
            questionId: '',
            deficiencyTypeId: types.length > 0 ? types[0].id : null
        });

        if (resp.deficiency)
            setSelectionId(resp.deficiency.id);
        
        getDeficiencies();
    };

    deficiencies.sort((a, z) => (qDeficiencies.includes(a.id) ? 0 : 1) - (qDeficiencies.includes(z.id) ? 0 : 1));

    return (
        <Dialog PaperProps={{ sx: { width: '80vh', height: '80vh', minWidth: '80vh' }}} open={isOpen} onClose={close}>
            <DialogTitle>{`Editing deficiencies for ${questionName}`}</DialogTitle>
            <Button variant="outlined" onClick={createNew}>
                New Deficiency
            </Button>
            <DeficienciesTable selectedDefs={qDeficiencies} selectionId={selectionId} surveyId={surveyId} questionId={questionId} deficiencies={deficiencies} types={types} refresh={getDeficiencies} />
            <DialogActions>
                <Button onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeficienciesModal;