import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ClientDTO, InspectionTypeDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ClientsTable from "../../../components/tables/ClientTable";
import { ClientsService, InspectionTypesService } from "../../../lib/api/api";

const EditSurvey: FunctionComponent = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [survey, setSurvey] = useState<InspectionTypeDTO>();
    const [surveyId, setSurveyId] = useState(params.surveyId as string);

    const getSurvey = async () => {
        const survey = await InspectionTypesService.getInspectionType(surveyId);

        if (survey.inspectionType) setSurvey(survey.inspectionType);
    };

    useEffect(() => {
        if (surveyId === 'new') {
            setSurvey({
                id: '',
                name: '',
                description: '',
                allowMultipleIssues: false,
                isSpecial: false,
                hasCompliance: true,
                colorOverride: '',
                idOverride: '',
                countQuestion: '',
                InspectionWarnings: [],
                QuestionCategoryGroups: [],
                ProjectScopes: [],
                Parts: []
            });
        } else {
            getSurvey();
        }
    }, [surveyId]);

    const setField = (field: string, v: string) => {
        setSurvey({
            ...survey!,
            [field]: v
        });
    }

    const saveClient = async () => {
        if (surveyId === 'new') {
            const newSurvey = await InspectionTypesService.createInspectionType(survey!);

            if (newSurvey.inspectionType) {
                navigate(`/admin/surveys/${newSurvey.inspectionType.id}/edit`, { replace: true })
            } else {
                console.log('error creating survey');
            }
        } else {
            const updateSurvey = await InspectionTypesService.updateInspectionType(survey!.id!, survey!);

            if (updateSurvey.inspectionType) {
                getSurvey();
            } else {
                console.log('error updating client');
            }
        }
    };

    return (
        <Stack width='100%'>
            {survey &&
                <Stack spacing={2}>
                    <TextField
                        id="survey-name"
                        label="Name"
                        variant="outlined"
                        value={survey.name}
                        onChange={(e) => setField('name', e.target.value)}
                        style={{ flex: 1 }}
                    />
                    <TextField
                        id="survey-description"
                        label="Description"
                        variant="outlined"
                        value={survey.description}
                        onChange={(e) => setField('description', e.target.value)}
                    />
                    <Button onClick={saveClient}>
                        Save
                    </Button>
                </Stack>
            }
            {!survey &&
                <>Loading</>
            }
        </Stack>
    );
}

export default EditSurvey;