import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { BlueprintDTO, InspectionIssueDTO, PinDTO } from '@premier/models';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { InspectionsService } from '../../../lib/api/api/services/InspectionsService';
import CustomAction from '../CustomAction';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import PreviewPinModal from '../../modals/PreviewPinModal';
import ArchiveAction from '../ArchiveAction';
import { useStores } from '../../../hooks/useStores';
import { toast } from 'react-toastify';

interface IProps {
    pins: PinDTO[];
    issues: InspectionIssueDTO[];
    blueprints: BlueprintDTO[];
    loading: boolean;
    jobId: string;
    refresh: () => { };
}

const IssuesTable: FunctionComponent<IProps> = ({ pins, jobId, blueprints, loading, issues, refresh }) => {
    const { rootStore } = useStores();
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState(issues);

    const [blueprintId, setBlueprintId] = useState('');
    const [pinId, setPinId] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => setRows(issues), [issues]);

    const columns: GridColDef<InspectionIssueDTO>[] = [
        {
            field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) =>
                <Stack direction='row'>
                    <CustomAction icon={<AspectRatioOutlinedIcon />} text='View on Blueprint' f={() => {
                        setBlueprintId(params.row.blueprintId);
                        setPinId(params.row.pinId);
                        setShow(true);
                    }} id={params.row.id} />
                    <ArchiveAction isArchived={params.row.hidden} onClick={async () => {
                        const ret = await rootStore.wsService?.toggleHiddenIssue(params.row.id);

                        if (!!!ret) {
                            toast.error('Operation failed.');
                            return;
                        }

                        refresh();
                    }} />
                </Stack>
        },
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.5
        },
        {
            field: 'pinId',
            headerName: 'Pin ID',
            flex: 0.5
        },
        {
            field: 'inspectionCompleted',
            headerName: 'Inspection Completed',
            flex: 0.5,
            valueGetter: (params) => params.row.inspectionCompleted ? new Date(params.row.inspectionCompleted) : 'No'
        }
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={columns}
                pageSize={25}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
            />
            {show &&
                <PreviewPinModal isOpen={show} close={() => setShow(false)} blueprint={blueprints.find((b) => b.id === blueprintId)} pin={pins.find((p) => p.id === pinId)} />
            }
        </Box>
    );
};

export default IssuesTable;
