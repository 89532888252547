import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AnswerDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import * as uuid from 'uuid';
import { AnswersService, QuestionsService } from "../../../lib/api/api";
import AnswersTable from "../../tables/AnswerTable";

interface IProps {
    close: () => void;
    isOpen: boolean;
    questionName: string;
    questionId: string;
}

const AnswersModal: FunctionComponent<IProps> = ({ questionId, questionName, isOpen, close }) => {
    const [answers, setAnswers] = useState<AnswerDTO[]>([]);

    const getAnswers = async () => {
        const resp  = await QuestionsService.getAnswers(questionId);
        setAnswers(resp);
    };

    useEffect(() => {
        if (uuid.validate(questionId))
            getAnswers();
    }, [questionId]);

    const createNew = async () => {
        await AnswersService.createAnswer({
            id: '',
            name: 'New Answer',
            value: 'New Answer',
            valueType: 'string',
            triggersDeficiency: false,
            triggersDoorRelabel: false,
            triggersExitRelabel: false,
            triggersFrameRelabel: false,
            order: answers.length > 0 ? answers[answers.length - 1].order + 1 : 1,
            triggers: undefined,
            forcedColor: undefined,
            forcedTxtColor: undefined,
            forcedId: undefined,
            isCount: false,
            tenantId: '',
            questionId: questionId
        });
        getAnswers();
    };

    return (
        <Dialog PaperProps={{ sx: { width: '80vh', height: '80vh', minWidth: '80vh' }}} open={isOpen} onClose={close}>
            <DialogTitle>{`Editing answers for ${questionName}`}</DialogTitle>
            <Button variant="outlined" onClick={createNew}>
                New Answer
            </Button>
            <AnswersTable questionId={questionId} answers={answers} refresh={getAnswers} />
            <DialogActions>
                <Button onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AnswersModal;