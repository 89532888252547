import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { AnswerDTO, AnswerRequest, AnswerResponse, AnswersResponse, OrderRequest } from "@premier/models";

export class AnswersService {
    public static async getAnswers(): Promise<AnswersResponse> {
        const [status, response] = await fetchApi<AnswersResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Answers/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getAnswersForQuestion(questionId: string): Promise<AnswersResponse> {
        const [status, response] = await fetchApi<AnswersResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Answers/q/${questionId}/all`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateOrder(data: OrderRequest): Promise<AnswerDTO[]> {
        const [status, response] = await fetchApi<AnswerDTO[]>(ApiSettings, {
            method: "PUT",
            path: `/api/Answers/order`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async getAnswer(id: string): Promise<AnswerResponse> {
        const [status, response] = await fetchApi<AnswerResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Answers/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteAnswer(id: string): Promise<AnswerResponse> {
        const [status, response] = await fetchApi<AnswerResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Answers/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateAnswer(id: string, data: AnswerDTO): Promise<AnswerResponse> {
        const [status, response] = await fetchApi<AnswerResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Answers/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createAnswer(data: AnswerDTO): Promise<AnswerResponse> {
        const [status, response] = await fetchApi<AnswerResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Answers/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}