import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { JobRequest, JobsResponse, JobResponse, BuildingsResponse, BuildingResponse, BuildingRequest, FloorsResponse } from "@premier/models";

export class BuildingsService {
    public static async getFloorsForBuilding(id: string): Promise<FloorsResponse> {
        const [status, response] = await fetchApi<FloorsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Buildings/${id}/Floors`,
            requiresAuth: true
        });

        return response;
    }

    public static async getBuildings(): Promise<BuildingsResponse> {
        const [status, response] = await fetchApi<BuildingsResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Buildings/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getBuilding(id: string): Promise<BuildingResponse> {
        const [status, response] = await fetchApi<BuildingResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Buildings/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteBuilding(id: string): Promise<BuildingResponse> {
        const [status, response] = await fetchApi<BuildingResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Buildings/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateBuilding(id: string, data: BuildingRequest): Promise<BuildingResponse> {
        const [status, response] = await fetchApi<BuildingResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Buildings/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createBuilding(data: BuildingRequest): Promise<BuildingResponse> {
        const [status, response] = await fetchApi<BuildingResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Buildings/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}