import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AnswerDTO, RoleDTO, GroupDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as uuid from 'uuid';
import { AccountsService, AnswersService, QuestionsService } from "../../../lib/api/api";
import AnswersTable from "../../tables/AnswerTable";

interface IProps {
    close: () => void;
    isOpen: boolean;
    refresh: () => Promise<void>;
    allRoles: RoleDTO[];
    allGroups: GroupDTO[];
}

const NewAccountModal: FunctionComponent<IProps> = ({ isOpen, close, refresh, allRoles, allGroups }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState<RoleDTO[]>([]);
    const [groups, setGroups] = useState<GroupDTO[]>([]);

    const createNew = async () => {
        if (firstName.length < 1 || lastName.length < 1 || email.length < 1) {
            toast('Missing a field.', { type: toast.TYPE.ERROR });
            return;
        }

        const allAccounts = await AccountsService.getAccounts();

        if (allAccounts.accounts && allAccounts.accounts.find((v) => v.email === email)) {
            toast('Account with email already exists.', { type: toast.TYPE.ERROR });
            return;
        }

        const resp = await AccountsService.createAccount({
            firstName,
            lastName,
            email,
            roles: roles.map((r) => r.id!),
            groups: groups.map((g) => g.id!)
        });

        if (resp.account) {
            close();
            refresh();
        } else {
            toast('Failed to create account.', { type: toast.TYPE.ERROR });
        }
    };

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>New Account</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} padding={2}>
                    <Grid item xs={6}>
                        <TextField id="firstName-edit" label="First Name" variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="lastName-edit" label="Last Name" variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="email-edit" label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            fullWidth
                            id="roles-autocomplete"
                            limitTags={2}
                            options={allRoles}
                            onChange={async (e, r) => setRoles(r)}
                            getOptionLabel={(option) => option?.name ?? 'N'}
                            value={roles}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Roles'
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            fullWidth
                            id="groups-autocomplete"
                            limitTags={2}
                            options={allGroups}
                            onChange={async (e, r) => setGroups(r)}
                            getOptionLabel={(option) => option?.name ?? 'N'}
                            value={groups}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Groups'
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
                <Button onClick={createNew}>Create</Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewAccountModal;