import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { BlueprintDTO, BuildingDTO, PinDTO } from '@premier/models';
import React, { FunctionComponent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as uuid from 'uuid';
import { ApiUtils } from '../../../lib/api/api/core/Utils';
import { BuildingsService } from '../../../lib/api/api/services/BuildingsService';
import Image from '../../basic/Image';

interface IProps {
    blueprint?: BlueprintDTO;
    pin?: PinDTO;
    isOpen: boolean;
    close: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: '50%'
};

const PreviewPinModal: FunctionComponent<IProps> = ({ blueprint, pin, isOpen, close }) => {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const positionRef = useCallback((node: HTMLDivElement) => {
        if (node !== null) {
            const widthMod = node.offsetWidth / blueprint!.width;
            const heightMod = node.offsetHeight / blueprint!.height;

            setX(pin!.x * widthMod);
            setY(pin!.y * heightMod);
        }
    }, []);

    if (!!!blueprint || !!!pin) {
        setTimeout(close, 250);
        return null;
    }

    return (
        <Modal
            open={isOpen}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} ref={positionRef}>
                <Image
                    style={{
                        //transform: 'rotate(90deg)'
                    }}
                    width='100%'
                    height='100%'
                    src={`${ApiUtils.getApiHttps()}/api/Images/download/${blueprint.imageId}`}
                    alt={blueprint.name}
                />
                <Image
                    width={20}
                    height={20}
                    style={{
                        position: 'absolute',
                        left: x - 10,
                        top: y - 10
                    }}
                    src='https://cdn0.iconfinder.com/data/icons/octicons/1024/x-512.png'
                />
            </Box>
        </Modal>
    );
};

export default PreviewPinModal;