import ImageOutlined from '@mui/icons-material/ImageOutlined';
import HideImageOutlined from '@mui/icons-material/HideImageOutlined';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRowModel, GridRowOrderChangeParams, GridValueGetterParams, MuiEvent, useGridApiRef } from '@mui/x-data-grid-pro';
import { AnswerDTO, BlueprintDTO, ClientDTO, QuestionDTO } from '@premier/models';
import { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { AnswersService, ClientsService, QuestionsService } from '../../../lib/api/api';
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import ArchiveAction from '../ArchiveAction';
import DeleteAction from '../DeleteAction';
import PreviewImageAction from '../PreviewImageAction';
import BlueprintUploader from '../LazyCells/BlueprintUploader';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import { SelectionType } from '../../../lib/api/api/enums/SelectionType';
import MenuItem from '@mui/material/MenuItem';
import EditAnswersAction from '../EditAnswersAction';

interface IProps {
    questionId: string;
    answers: AnswerDTO[];
    refresh: () => Promise<void>;
}

const update = async (newRow: GridRowModel<AnswerDTO>) => {
    const resp = await AnswersService.updateAnswer(newRow.id, { ...newRow, name: newRow.value });
    return resp.answer!;
}

const updateOrder = async (ids: string[]) => {
    const resp = await AnswersService.updateOrder({ order: ids });
    return resp;
}

const AnswersTable: FunctionComponent<IProps> = ({ answers, questionId, refresh }) => {
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(answers);

    useEffect(() => setRows(answers), [answers]);

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setLoading(true);

        const selectedRows = apiRef.current.getSelectedRows();
        const hasDragged = selectedRows.has(params.row.id);
        if (hasDragged) {
            const selections = [...rows].filter((r) => selectedRows.has(r.id));
            const rowsClone = [...rows].filter((r) => !selections.map((v) => v.id).includes(r.id));

            rowsClone.splice(params.targetIndex, 0, ...(selections as AnswerDTO[]));

            const newRows = await updateOrder(rowsClone.map((r) => r.id));

            apiRef.current.setSelectionModel([]);

            setRows(newRows);
        } else {
            const rowsClone = [...rows];
            const row = rowsClone.splice(params.oldIndex, 1)[0];
            rowsClone.splice(params.targetIndex, 0, row);

            const newRows = await updateOrder(rowsClone.map((r) => r.id));

            setRows(newRows);
        }
        setLoading(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) =>
                <Stack direction='row'>

                </Stack>
        },
        {
            field: 'value',
            headerName: 'Value',
            flex: 0.5,
            editable: true
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 0.5,
            editable: true
        },
        {
            field: 'default',
            headerName: 'Default Answer?',
            renderCell: (params) => <Checkbox checked={params.row.order === 0} onChange={(e) => toggleDefault(params.row)} />,
            flex: 0.5
        },
        {
            field: 'triggersDeficiency',
            headerName: 'Triggers Deficiency?',
            renderCell: (params) => <Checkbox checked={params.row.triggersDeficiency} onChange={(e) => toggleDef(params.row)} />,
            flex: 0.5
        }
    ];

    const toggleDefault = async (row: AnswerDTO) => {
        if (row.order === 0) {
            await AnswersService.updateAnswer(row.id, { ...row, order: 1 });
        } else {
            for (const r of answers) {
                if (r.order === 0) {
                    await AnswersService.updateAnswer(r.id, { ...r, order: 1 });
                }
            }
            await AnswersService.updateAnswer(row.id, { ...row, order: 0 });
        }

        refresh();
    }

    const toggleDef = async (row: AnswerDTO) => {
        await AnswersService.updateAnswer(row.id, { ...row, triggersDeficiency: !row.triggersDeficiency });

        refresh();
    }

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={columns}
                pageSize={25}
                experimentalFeatures={{ newEditingApi: true }}
                rowReordering
                processRowUpdate={update}
                onRowOrderChange={handleRowOrderChange}
            />
        </Box>
    );
};

export default AnswersTable;
