import React, { FunctionComponent, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate
} from "react-router-dom";
import About from './routes/about';
import Home from './routes/home';
import Profile from './routes/profile';
import MainNavigation from './components/MainNavigation';
import { useStores } from './hooks/useStores';
import LoginForm from './components/forms/LoginForm';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { ApiSettings } from './lib/api/api';
import Clients from './routes/admin/clients/clients';
import Jobs from './routes/admin/clients/jobs';
import JobsAdmin from './routes/admin/jobs/jobs';
import JobAdmin from './routes/admin/jobs/job';
import Client from './routes/admin/clients/client';
import EditClient from './routes/admin/clients/EditClient';
import Buildings from './routes/admin/clients/buildings';
import Blueprints from './routes/admin/clients/blueprints';
import { WebSocketService } from './lib/api/api/WebSocketService';
import { ToastContainer } from 'react-toastify';
import Reports from './routes/admin/reports/reports';
import Job from './routes/admin/reports/job';
import GeneralReport from './routes/admin/reports/reports/general';
import Surveys from './routes/admin/surveys/surveys';
import Survey from './routes/admin/surveys/survey';
import EditSurvey from './routes/admin/surveys/EditSurvey';
import QuestionGroups from './routes/admin/surveys/groups';
import QuestionCategories from './routes/admin/surveys/categories';
import Questions from './routes/admin/surveys/questions';
import Deficiencies from './routes/admin/surveys/deficiencies';
import InspectionReport from './routes/admin/reports/reports/InspectionReport';
import Accounts from './routes/admin/accounts/accounts';
import LSPReport from './routes/admin/reports/reports/LSPReport';
import JobIncompletes from './routes/admin/jobs/incompletes';
import JobHiddens from './routes/admin/jobs/hidden';
import JobInspections from './routes/admin/jobs/inspections';

const App: FunctionComponent = observer(() => {
    const { rootStore } = useStores();

    useEffect(() => {
        const refreshLogin = async () => {
            if (rootStore.authStore.token === null) {
                runInAction(() => rootStore.authStore.loggedIn = false)
                return;
            };

            ApiSettings.token = rootStore.authStore.token;

            await rootStore.authStore.extendTokenAsync();
            await rootStore.authStore.updateUser();

            runInAction(() => {
                if (rootStore.wsService && rootStore.wsService.isConnected())
                    rootStore.wsService.destroy();
                rootStore.wsService = new WebSocketService();
            });
        };

        refreshLogin();
    }, []);

    return (
        <>
            {rootStore.authStore.loggedIn &&
                <Router>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                        <nav>
                            <MainNavigation />
                        </nav>

                        <Routes>
                            <Route path="/admin/accounts" element={<Accounts />} />
                            <Route path="/admin/clients" element={<Clients />}>
                                <Route path=":clientId" element={<Client />}>
                                    <Route path="edit" element={<EditClient />} />
                                    <Route path="buildings" element={<Buildings />} />
                                    <Route path="blueprints" element={<Blueprints />} />
                                    <Route path="jobs" element={<Jobs />} />
                                </Route>
                            </Route>
                            <Route path="/admin/jobs" element={<JobsAdmin />}>
                                <Route path=":jobId" element={<JobAdmin />}>
                                    <Route path="inspections" element={<JobInspections />} />
                                    <Route path="incompletes" element={<JobIncompletes />} />
                                    <Route path="hidden" element={<JobHiddens />} />
                                </Route>
                            </Route>
                            <Route path="/admin/surveys" element={<Surveys />}>
                                <Route path=":surveyId" element={<Survey />}>
                                    <Route path="edit" element={<EditSurvey />} />
                                    <Route path="groups" element={<QuestionGroups />} />
                                    <Route path="categories" element={<QuestionCategories />} />
                                    <Route path="questions" element={<Questions />} />
                                    <Route path="deficiencies" element={<Deficiencies />} />
                                </Route>
                            </Route>
                            <Route path="/admin/reports" element={<Reports />}>
                                <Route path=":jobId" element={<Job />}>
                                    <Route path="summary" element={<GeneralReport name='DefReport' />} />
                                    <Route path="tm-report" element={<GeneralReport name='TMReport' />} />
                                    <Route path="parts" element={<GeneralReport name='PartsReport' />} />
                                    <Route path="internal" element={<GeneralReport name='InternalReport' />} />
                                    <Route path="facility-notes" element={<GeneralReport name='FacNotesReport' />} />
                                    <Route path="door-order" element={<GeneralReport name='DoorOrderReport' />} />
                                    <Route path="door-finish" element={<GeneralReport name='DoorFinishReport' />} />
                                    <Route path="door-spec" element={<GeneralReport name='DoorSpecReport' />} />
                                    <Route path="vcu" element={<GeneralReport name='VCU' />} />
                                    <Route path="labeled-lsp" element={<LSPReport name='LabeledLSPReport' />} />
                                    <Route path="counted-lsp" element={<LSPReport name='CountedLSPReport' />} />
                                    <Route path="inspection" element={<InspectionReport name='InspectionReport' />} />
                                </Route>
                            </Route>
                            <Route path="/about" element={<About />} />
                            <Route path="/users" element={<Profile />} />
                            <Route path="/" element={<Home />} />
                        </Routes>
                    </div>
                </Router>
            }
            {!rootStore.authStore.loggedIn &&
                <LoginForm />
            }
            <ToastContainer />
        </>
    );
});

export default App;
