import { FunctionComponent, useState } from "react";
import Stack from "@mui/material/Stack";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface IProps {
    f: (id: string) => Promise<boolean>;
    id: string;
    text: string;
    icon?: JSX.Element;
}

const AsyncAction: FunctionComponent<IProps> = ({ f, id, text, icon = <BuildOutlinedIcon /> }) => {
    const [loading, setLoading] = useState(false);
    const [iconState, setIconState] = useState<'' | 'y' | 'n'>('');
    return (
        <Stack width='100%' alignItems='center'>
            <Tooltip title={text}>
                <Stack>
                    {!loading &&
                        <IconButton onClick={async () => {
                            setLoading(true);
                            const ret = await f(id);
                            setIconState(ret ? 'y' : 'n');
                            setLoading(false);
                            setTimeout(() => setIconState(''), 1000);
                        }}>
                            {iconState === '' &&
                                icon
                            }
                            {iconState === 'y' &&
                                <CheckCircleIcon color="success" />
                            }
                            {iconState === 'n' &&
                                <CheckCircleIcon color="error" />
                            }
                        </IconButton>
                    }
                    {loading &&
                        <CircularProgress size={20} />
                    }
                </Stack>
            </Tooltip>
        </Stack>
    );
};

export default AsyncAction;