import ImageOutlined from '@mui/icons-material/ImageOutlined';
import HideImageOutlined from '@mui/icons-material/HideImageOutlined';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRowModel, GridRowOrderChangeParams, GridValueGetterParams, MuiEvent, useGridApiRef } from '@mui/x-data-grid-pro';
import { BlueprintDTO, ClientDTO, DeficiencyDTO, DeficiencyTypeDTO, QuestionDTO } from '@premier/models';
import { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { ClientsService, QuestionsService } from '../../../lib/api/api';
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import ArchiveAction from '../ArchiveAction';
import DeleteAction from '../DeleteAction';
import PreviewImageAction from '../PreviewImageAction';
import BlueprintUploader from '../LazyCells/BlueprintUploader';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import { SelectionType } from '../../../lib/api/api/enums/SelectionType';
import MenuItem from '@mui/material/MenuItem';
import EditAnswersAction from '../EditAnswersAction';
import { DeficienciesService } from '../../../lib/api/api/services/DeficiencyService';
import * as uuid from 'uuid';
import CustomAction from '../CustomAction';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

interface IProps {
    surveyId: string;
    deficiencies: DeficiencyDTO[];
    selectedDefs?: string[];
    types: DeficiencyTypeDTO[];
    selectionId: string;
    questionId?: string;
    refresh: () => Promise<void>;
}

const update = async (newRow: GridRowModel<DeficiencyDTO>) => {
    const resp = await DeficienciesService.updateDeficiency(newRow.id, { ...newRow });
    return resp.deficiency!;
}

const DeficienciesTable: FunctionComponent<IProps> = ({ deficiencies, surveyId, questionId, types, selectedDefs, selectionId, refresh }) => {
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(deficiencies);
    const selectionIdRef = useRef(selectionId);

    useEffect(() => setRows(deficiencies), [deficiencies]);
    useEffect(() => {
        if (selectionIdRef.current !== selectionId) {
            apiRef.current.selectRow(selectionId, true, true);
            const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(selectionId);
            apiRef.current.scrollToIndexes({ colIndex: 0, rowIndex });
        }
    }, [selectionId]);

    const columns: GridColDef[] = [
        {
            field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) =>
                <Stack direction='row'>
                    {questionId && uuid.validate(questionId) &&
                        <CustomAction
                            icon={<AddOutlinedIcon />}
                            toggledIcon={<ClearOutlinedIcon />}
                            isToggled={selectedDefs!.includes(params.row.id)}
                            id={params.row.id}
                            text={selectedDefs!.includes(params.row.id) ? 'Remove Deficiency' : 'Add Deficiency'}
                            f={async (id) => {
                                await QuestionsService.addDeficiency(questionId, id);
                                refresh();
                            }} />
                    }
                </Stack>
        },
        {
            field: 'hidden', headerName: 'Hidden', flex: 0.25, renderCell: (params) =>
                <ArchiveAction
                    isArchived={params.row.hidden}
                    archivedMessage='Show Deficiency'
                    message='Hide Deficiency'
                />
        },
        {
            field: 'displayText',
            headerName: 'Text',
            flex: 1,
            editable: true
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 0.5,
            editable: true
        },
        {
            field: 'resolves',
            headerName: 'Resolves?',
            renderCell: (params) => <Checkbox checked={params.row.resolves} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.5,

        },
        {
            field: 'closes',
            headerName: 'Closes?',
            renderCell: (params) => <Checkbox checked={params.row.closes} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.5,

        },
        {
            field: 'requiresPhase2',
            headerName: 'Requires Phase 2?',
            renderCell: (params) => <Checkbox checked={params.row.requiresPhase2} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.5,

        },
        {
            field: 'requiresPhase3',
            headerName: 'Requires Phase 3?',
            renderCell: (params) => <Checkbox checked={params.row.requiresPhase3} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.5,

        },
        {
            field: 'requiresRelabel',
            headerName: 'Requires Relabel?',
            renderCell: (params) => <Checkbox checked={params.row.requiresRelabel} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.5,

        },
        {
            field: 'isRelabelOption',
            headerName: 'is Relabel Option?',
            renderCell: (params) => <Checkbox checked={params.row.isRelabelOption} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.5,

        },
        {
            field: 'isClientAddressOption',
            headerName: 'Is Client Address Option?',
            renderCell: (params) => <Checkbox checked={params.row.isClientAddressOption} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.5,

        },
        {
            field: 'labelApplied',
            headerName: 'Label Applied?',
            renderCell: (params) => <Checkbox checked={params.row.labelApplied} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.5,

        },
        {
            field: 'relabelType',
            headerName: 'Relabel Type',
            flex: 0.25,

        },
        {
            field: 'replacementType',
            headerName: 'Replacement Type',
            flex: 0.25,

        },
        {
            field: 'deficiencyTypeId',
            headerName: 'Deficiency Type',
            flex: 0.75,
            renderCell: (params) =>
                <Select
                    fullWidth
                    //disabled
                    id={`defType-select-${params.row.id}`}
                    value={params.row.deficiencyTypeId}
                    label="Deficiency Type"
                    onChange={(e) => defTypeChange(e.target.value, params.row)}
                >
                    {types.map((g) => <MenuItem key={`${g.id}-${params.row.id}`} value={g.id}>{g.name}</MenuItem>)}
                </Select>
        }
    ];

    const checkedChange = async (checked: boolean, field: string, row: DeficiencyDTO) => {
        await DeficienciesService.updateDeficiency(row.id, { ...row, [field]: checked });

        refresh();
    }

    const defTypeChange = async (value: string, row: DeficiencyDTO) => {
        await DeficienciesService.updateDeficiency(row.id, { ...row, deficiencyTypeId: value });

        refresh();
    }

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={columns}
                pageSize={25}
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={update}
                onCellClick={async (params, event, details) => {
                    if (params.field === 'hidden') {
                        //const bResp = await DeficienciesService.updateDeficiency(params.row.id, {...params.row, hidden: !params.row.hidden });
                        refresh();
                    }
                }}
            />
        </Box>
    );
};

export default DeficienciesTable;
