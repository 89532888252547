import { computed, makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { WebSocketService } from '../lib/api/api/WebSocketService';
import { AuthenticationStore } from './AuthenticationStore';

export interface Notification {
    id: string;
    type: 'error'|'warning'|'success';
    title?: string;
    description: string;
    time: Date;
}

export const MAX_NOTIFICATIONS = 30;

export class RootStore {
    wsService?: WebSocketService;

    authStore: AuthenticationStore = new AuthenticationStore();

    constructor() {
        makeAutoObservable(this);
    }
}

const rootStore = new RootStore();
export default rootStore;
