import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { BuildingDTO, SocketClientInfoDto } from '@premier/models';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as uuid from 'uuid';
import { useStores } from '../../../hooks/useStores';
import { BuildingsService } from '../../../lib/api/api/services/BuildingsService';
import saveAs from 'file-saver';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';

interface IProps {
    socketId: string;
    isOpen: boolean;
    close: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UserInfoModal: FunctionComponent<IProps> = ({ socketId, isOpen, close }) => {
    const { rootStore } = useStores();
    const [loading, setLoading] = useState(true);
    const [clientInfo, setClientInfo] = useState<SocketClientInfoDto>();

    useEffect(() => {
        const getClientInfo = async () => {
            const info = await rootStore.wsService?.retrieveUserInfo(socketId);
            setClientInfo(info);
            setLoading(false);
        };

        getClientInfo();
    }, [socketId]);

    return (
        <Modal
            open={isOpen}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack>
                    {!!clientInfo &&
                        <React.Fragment>
                            <Typography>
                                Name: {clientInfo.name}
                            </Typography>
                            <Typography>
                                Status: {clientInfo.status}
                            </Typography>
                            <Typography>
                                Socket ID: {clientInfo.sId}
                            </Typography>
                            {clientInfo.nativeVersion &&
                                <Typography>
                                    Native App Version: {clientInfo.nativeVersion}
                                </Typography>
                            }
                            {clientInfo.otaUpdateId &&
                                <Typography>
                                    OTA Update ID: {clientInfo.otaUpdateId}
                                </Typography>
                            }
                            {clientInfo.currentActivity &&
                                <Typography>
                                    Current Activity: {clientInfo.currentActivity}
                                </Typography>
                            }
                             <Button onClick={async () => {
                                    const ret = await rootStore.wsService?.retrieveJobDatabase(clientInfo.sId);

                                    if (ret?.db64 && ret.wal64) {
                                        const blob = new Blob([Buffer.from(ret.db64, 'base64')]);
                                        const blob2 = new Blob([Buffer.from(ret.wal64, 'base64')]);
                                        saveAs(blob, `main.db`);
                                        saveAs(blob2, `main.db-wal`);
                                    } else {

                                    }
                                }}>
                                    Download Main Database
                                </Button>
                            {!!clientInfo.inspectionId &&
                                <Button onClick={async () => {
                                    const ret = await rootStore.wsService!.retrieveJobDatabase(clientInfo.sId, clientInfo.inspectionId!);

                                    if (ret.db64.length > 0 && ret.wal64.length > 0) {
                                        const blob = new Blob([Buffer.from(ret.db64, 'base64')]);
                                        const blob2 = new Blob([Buffer.from(ret.wal64, 'base64')]);
                                        saveAs(blob, `inspection-${clientInfo.inspectionId}.db`);
                                        saveAs(blob2, `inspection-${clientInfo.inspectionId}.db-wal`);
                                    } else {
                                        toast.error('Job database is too large to download.');
                                    }
                                }}>
                                    Download Job Database
                                </Button>
                            }
                        </React.Fragment>
                    }
                    {!!!clientInfo &&
                        <Typography>
                            Loading...
                        </Typography>
                    }
                </Stack>
            </Box>
        </Modal>
    );
};

export default UserInfoModal;