import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AccountDTO, BuildingDTO, ClientDTO, GroupDTO, RoleDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BuildingModal from "../../../components/modals/BuildingModal";
import NewAccountModal from "../../../components/modals/NewAccountModal";
import AccountsTable from "../../../components/tables/AccountsTable";
import BuildingsTable from "../../../components/tables/BuildingTable";
import ClientsTable from "../../../components/tables/ClientTable";
import { AccountsService, ClientsService } from "../../../lib/api/api";
import { GroupsService } from "../../../lib/api/api/services/GroupsService";
import { RolesService } from "../../../lib/api/api/services/RolesService";

const Accounts: FunctionComponent = () => {
    const params = useParams();

    const [roles, setRoles] = useState<RoleDTO[]>([]);
    const [groups, setGroups] = useState<GroupDTO[]>([]);
    const [accounts, setAccounts] = useState<AccountDTO[]>([]);
    const [editId, setEditId] = useState('');
    const [show, setShow] = useState(false);

    const clientId = params.clientId as string;

    const getRoles = async () => {
        const resp = await RolesService.getRoles();

        if (resp.roles) setRoles(resp.roles);
    };

    const getGroups = async () => {
        const resp = await GroupsService.getGroups();

        if (resp.groups) setGroups(resp.groups);
    };

    const getAccounts = async () => {
        const resp = await AccountsService.getAccounts();

        if (resp.accounts) setAccounts(resp.accounts);
    };

    useEffect(() => {
        getRoles();
        getGroups();
        getAccounts();
    }, []);

    return (
        <Stack flex={1} spacing={2} padding={2}>
            <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={() => {
                setEditId('');
                setShow(true);
            }}>
                New Account
            </Button>
            <AccountsTable roles={roles} groups={groups} accounts={accounts} refresh={getAccounts} />
            {show &&
                <NewAccountModal allRoles={roles} allGroups={groups} close={() => setShow(false)} isOpen={show} refresh={getAccounts} />
            }
        </Stack>
    );
}

export default Accounts;