import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { OrderRequest, QuestionCategoriesResponse, QuestionCategoryGroupDTO, QuestionCategoryRequest, QuestionCategoryResponse } from "@premier/models";

export class QuestionCategoryGroupsService {
    private static _apiEndpoint = 'QuestionCategoryGroups';

    public static async getQuestionCategoryGroups(): Promise<QuestionCategoryGroupDTO[]> {
        const [status, response] = await fetchApi<QuestionCategoryGroupDTO[]>(ApiSettings, {
            method: "GET",
            path: `/api/${this._apiEndpoint}/all`,
            requiresAuth: true
        });

        return response;
    }

    public static async getQuestionCategoryGroup(id: string): Promise<QuestionCategoryGroupDTO> {
        const [status, response] = await fetchApi<QuestionCategoryGroupDTO>(ApiSettings, {
            method: "GET",
            path: `/api/${this._apiEndpoint}/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteQuestionCategoryGroup(id: string): Promise<QuestionCategoryResponse> {
        const [status, response] = await fetchApi<QuestionCategoryResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/${this._apiEndpoint}/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateQuestionCategoryGroup(id: string, data: QuestionCategoryGroupDTO): Promise<QuestionCategoryGroupDTO> {
        const [status, response] = await fetchApi<QuestionCategoryGroupDTO>(ApiSettings, {
            method: "PUT",
            path: `/api/${this._apiEndpoint}/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async updateOrder(data: OrderRequest): Promise<QuestionCategoryGroupDTO[]> {
        const [status, response] = await fetchApi<QuestionCategoryGroupDTO[]>(ApiSettings, {
            method: "PUT",
            path: `/api/${this._apiEndpoint}/order`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createQuestionCategoryGroup(data: QuestionCategoryGroupDTO): Promise<QuestionCategoryGroupDTO> {
        const [status, response] = await fetchApi<QuestionCategoryGroupDTO>(ApiSettings, {
            method: "POST",
            path: `/api/${this._apiEndpoint}/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}