import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { BlueprintDTO, ClientDTO, DeficiencyDTO, DeficiencyTypeDTO, JobDTO, QuestionCategoryDTO, QuestionDTO } from "@premier/models";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BlueprintsTable from '../../../components/tables/BlueprintTable';
import ClientsTable from "../../../components/tables/ClientTable";
import { useStores } from '../../../hooks/useStores';
import { ClientsService, InspectionTypesService, QuestionCategoriesService, QuestionsService } from "../../../lib/api/api";
import * as uuid from 'uuid';
import QuestionsTable from '../../../components/tables/QuestionTable';
import AnswersModal from '../../../components/modals/AnswersModal';
import { DeficienciesService } from '../../../lib/api/api/services/DeficiencyService';
import DeficienciesTable from '../../../components/tables/DeficienciesTable';

const Deficiencies: FunctionComponent = () => {
    const { rootStore } = useStores();
    const params = useParams();
    const navigate = useNavigate();

    const [deficiencies, setDeficiencies] = useState<DeficiencyDTO[]>([]);
    const [types, setTypes] = useState<DeficiencyTypeDTO[]>([]);
    const [selectionId, setSelectionId] = useState('');

    const surveyId = params.surveyId as string;

    const getDeficiencies = async () => {
        const resp = await DeficienciesService.getDeficiencies();

        if (resp.deficiencies) {
            setDeficiencies(resp.deficiencies);
        }
    };

    const getDeficiencyTypes = async () => {
        const resp = await DeficienciesService.getDeficiencyTypes();

        if (resp) {
            setTypes(resp);
        }

        getDeficiencies();
    };

    useEffect(() => {
        getDeficiencyTypes();
    }, [surveyId]);

    const createNew = async () => {
        const resp = await DeficienciesService.createDeficiency({
            id: '',
            displayText: 'New deficiency',
            description: '',
            order: 0,
            triggerDeficiency: false,
            hidden: false,
            resolves: false,
            closes: false,
            requiresPhase2: false,
            requiresPhase3: false,
            requiresRelabel: false,
            isRelabelOption: false,
            isClientAddressOption: false,
            labelApplied: false,
            relabelType: null,
            replacementType: null,
            oldId: null,
            tenantId: '',
            questionId: '',
            deficiencyTypeId: types.length > 0 ? types[0].id : null
        });

        if (resp.deficiency)
            setSelectionId(resp.deficiency.id);
        
        getDeficiencies();
    }

    return (
        <Stack width='100%' flex={1} spacing={2}>
            <Button variant="outlined" onClick={createNew}>
                New Deficiency
            </Button>
            <DeficienciesTable selectionId={selectionId} types={types} deficiencies={deficiencies} surveyId={surveyId} refresh={getDeficiencies} />
        </Stack>
    );
}

export default Deficiencies;