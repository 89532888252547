export enum SelectionType {
    MULTI_SELECT = "MULTI_SELECT",
    SINGLE_SELECT = "SINGLE_SELECT",
    GROUP_SELECT = "GROUP_SELECT",
    NUMBER = "NUMBER",
    TEXT = "TEXT",
    RADIOBOX = "RADIOBOX",
    BARCODE = "BARCODE",
    SWITCH = "SWITCH",
    IMAGE = "IMAGE",
    THUMBNAIL = "THUMBNAIL",
    MEASUREMENT = "MEASUREMENT",
    PART_LIST = "PART_LIST",
    BUILDING_SELECT = "BUILDING_SELECT"
}