import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { DeficiencyResponse, InspectionDeficiencyRequest, InspectionTypeDTO, InspectionTypeRequest, InspectionTypeResponse, InspectionTypesResponse, QuestionCategoryDTO, QuestionCategoryGroupDTO } from "@premier/models";

export class InspectionTypesService {
    public static async getInspectionTypes(): Promise<InspectionTypesResponse> {
        const [status, response] = await fetchApi<InspectionTypesResponse>(ApiSettings, {
            method: "GET",
            path: "/api/InspectionTypes/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getGroups(id: string): Promise<QuestionCategoryGroupDTO[]> {
        const [status, response] = await fetchApi<QuestionCategoryGroupDTO[]>(ApiSettings, {
            method: "GET",
            path: `/api/InspectionTypes/${id}/Groups`,
            requiresAuth: true
        });

        return response;
    }

    public static async getGroupsByJob(jobId: string): Promise<QuestionCategoryGroupDTO[]> {
        const [status, response] = await fetchApi<QuestionCategoryGroupDTO[]>(ApiSettings, {
            method: "GET",
            path: `/api/InspectionTypes/j/${jobId}/Groups`,
            requiresAuth: true
        });

        return response;
    }

    public static async getCategories(id: string): Promise<QuestionCategoryDTO[]> {
        const [status, response] = await fetchApi<QuestionCategoryDTO[]>(ApiSettings, {
            method: "GET",
            path: `/api/InspectionTypes/${id}/Categories`,
            requiresAuth: true
        });

        return response;
    }

    public static async getInspectionType(id: string): Promise<InspectionTypeResponse> {
        const [status, response] = await fetchApi<InspectionTypeResponse>(ApiSettings, {
            method: "GET",
            path: `/api/InspectionTypes/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteInspectionType(id: string): Promise<InspectionTypeResponse> {
        const [status, response] = await fetchApi<InspectionTypeResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/InspectionTypes/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateInspectionType(id: string, data: InspectionTypeDTO): Promise<InspectionTypeResponse> {
        const [status, response] = await fetchApi<InspectionTypeResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/InspectionTypes/${id}`,
            requiresAuth: true,
            body: data,
            mediaType: "application/json"
        });

        return response;
    }

    public static async createInspectionType(data: InspectionTypeDTO): Promise<InspectionTypeResponse> {
        const [status, response] = await fetchApi<InspectionTypeResponse>(ApiSettings, {
            method: "POST",
            path: `/api/InspectionTypes/create`,
            requiresAuth: true,
            body: data,
            mediaType: "application/json"
        });

        return response;
    }

    public static async updateInspectionTypeDeficiencies(id: string, data: InspectionDeficiencyRequest[]) {
        const [status, response] = await fetchApi<InspectionTypeResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/InspectionTypes/${id}/Deficiencies`,
            requiresAuth: true,
            body: data,
            mediaType: "application/json"
        });

        return response;
    }

    public static async getInspectionTypeDeficiency(id: string, defId: string) {
        const [status, response] = await fetchApi<DeficiencyResponse>(ApiSettings, {
            method: "GET",
            path: `/api/InspectionTypes/${id}/Deficiency/${defId}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteInspectionTypeDeficiency(id: string, defId: string) {
        const [status, response] = await fetchApi<DeficiencyResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/InspectionTypes/${id}/Deficiency/${defId}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateInspectionTypeDeficiency(id: string, data: InspectionDeficiencyRequest) {
        const [status, response] = await fetchApi<InspectionTypeResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/InspectionTypes/${id}/Deficiency`,
            requiresAuth: true,
            body: data,
            mediaType: "application/json"
        });

        return response;
    }
}