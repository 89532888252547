import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { BuildingDTO } from '@premier/models';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as uuid from 'uuid';
import { ApiUtils } from '../../../lib/api/api/core/Utils';
import { BuildingsService } from '../../../lib/api/api/services/BuildingsService';
import Image from '../../basic/Image';

interface IProps {
    imageId: string;
    rotation: number;
    name: string;
    isOpen: boolean;
    close: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: '50%'
};

const PreviewImageModal: FunctionComponent<IProps> = ({ imageId, name, rotation, isOpen, close }) => {
    return (
        <Modal
            open={isOpen}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Image
                    style={{
                        transform: 'rotate(90deg);'
                    }}
                    width='100%'
                    height='100%'
                    src={`${ApiUtils.getApiHttps()}/api/Images/download/${imageId}`}
                    alt={name}
                />
            </Box>
        </Modal>
    );
};

export default PreviewImageModal;