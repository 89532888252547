import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { OrderRequest, QuestionCategoriesResponse, QuestionCategoryDTO, QuestionCategoryRequest, QuestionCategoryResponse, QuestionDTO } from "@premier/models";

export class QuestionCategoriesService {
    public static async getQuestionCategories(): Promise<QuestionCategoriesResponse> {
        const [status, response] = await fetchApi<QuestionCategoriesResponse>(ApiSettings, {
            method: "GET",
            path: "/api/QuestionCategories/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getQuestionCategory(id: string): Promise<QuestionCategoryResponse> {
        const [status, response] = await fetchApi<QuestionCategoryResponse>(ApiSettings, {
            method: "GET",
            path: `/api/QuestionCategories/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async getQuestions(id: string): Promise<QuestionDTO[]> {
        const [status, response] = await fetchApi<QuestionDTO[]>(ApiSettings, {
            method: "GET",
            path: `/api/QuestionCategories/${id}/Questions`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateOrder(data: OrderRequest): Promise<QuestionCategoryDTO[]> {
        const [status, response] = await fetchApi<QuestionCategoryDTO[]>(ApiSettings, {
            method: "PUT",
            path: `/api/QuestionCategories/order`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async deleteQuestionCategory(id: string): Promise<QuestionCategoryResponse> {
        const [status, response] = await fetchApi<QuestionCategoryResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/QuestionCategories/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateQuestionCategory(id: string, data: QuestionCategoryDTO): Promise<QuestionCategoryResponse> {
        const [status, response] = await fetchApi<QuestionCategoryResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/QuestionCategories/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createQuestionCategory(data: QuestionCategoryDTO): Promise<QuestionCategoryResponse> {
        const [status, response] = await fetchApi<QuestionCategoryResponse>(ApiSettings, {
            method: "POST",
            path: `/api/QuestionCategories/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}