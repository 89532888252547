import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { DeficienciesResponse, DeficiencyResponse, DeficiencyRequest, DeficiencyDTO, DeficiencyTypeDTO } from "@premier/models";

export class DeficienciesService {
    public static async getDeficiencies(): Promise<DeficienciesResponse> {
        const [status, response] = await fetchApi<DeficienciesResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Deficiencies/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getDeficiencyTypes(): Promise<DeficiencyTypeDTO[]> {
        const [status, response] = await fetchApi<DeficiencyTypeDTO[]>(ApiSettings, {
            method: "GET",
            path: "/api/Deficiencies/types/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getDeficiency(id: string): Promise<DeficiencyResponse> {
        const [status, response] = await fetchApi<DeficiencyResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Deficiencies/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteDeficiency(id: string): Promise<DeficiencyResponse> {
        const [status, response] = await fetchApi<DeficiencyResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Deficiencies/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateDeficiency(id: string, data: DeficiencyDTO): Promise<DeficiencyResponse> {
        const [status, response] = await fetchApi<DeficiencyResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Deficiencies/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createDeficiency(data: DeficiencyDTO): Promise<DeficiencyResponse> {
        const [status, response] = await fetchApi<DeficiencyResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Deficiencies/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}