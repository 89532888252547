import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { DataGridPro, GridColDef, GridRowModel, GridRowOrderChangeParams, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { BuildingDTO, ClientDTO, QuestionCategoryDTO, QuestionCategoryGroupDTO } from '@premier/models';
import { getgroups } from 'process';
import { FunctionComponent, useState } from 'react';
import { QuestionCategoryGroupsService } from '../../../lib/api/api/services/QuestionCategoryGroupsService';
import EditAction from '../EditAction';
import * as uuid from 'uuid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

interface IProps {
    groups: QuestionCategoryGroupDTO[];
    loading: boolean;
    refresh: () => Promise<void>;
}

const updateGroup = async (newRow: GridRowModel<QuestionCategoryGroupDTO>) => {
    const resp = await QuestionCategoryGroupsService.updateQuestionCategoryGroup(newRow.id, newRow);
    return resp;
}

const updateOrder = async (ids: string[]) => {
    const resp = await QuestionCategoryGroupsService.updateOrder({order: ids});
    return resp;
}

const QuestionGroupsTable: FunctionComponent<IProps> = ({ groups, loading, refresh }) => {
    const apiRef = useGridApiRef();
    const [localLoading, setLocalLoading] = useState(false);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.25,
            editable: true
        },
        {
            field: 'defaultGroup',
            headerName: 'Is Default Group?',
            flex: 0.25,
            renderCell: (params) => <Checkbox checked={params.row.defaultGroup} onChange={(e) => defaultChange(e.target.checked, params.row)} />
        },
        {
            field: 'specSheet',
            headerName: 'Is Spec Sheet?',
            flex: 0.25,
            renderCell: (params) => <Checkbox checked={params.row.specSheet} onChange={(e) => specSheetChange(e.target.checked, params.row)} />
        },
        {
            field: 'specSheetId',
            headerName: 'Triggers Spec Sheet?',
            flex: 0.25,
            renderCell: (params) => params.row.specSheet ? <Typography>N/A</Typography> :
                <Select
                    fullWidth
                    id={`specSheetId-select-${params.row.id}`}
                    value={params.row.specSheetId}
                    label="Triggered Spec Sheet"
                    onChange={(e) => specSheetIdChange(e.target.value, params.row)}
                >
                    {groups.filter((g) => g.specSheet).map((g) => <MenuItem value={g.id}>{g.name}</MenuItem>)}
                </Select>
        }
    ];

    const defaultChange = async (checked: boolean, row: QuestionCategoryGroupDTO) => {
        if (checked) {
            let removeSpecSheet = row.specSheet;
            for (const group of groups) {
                if (group.defaultGroup || (removeSpecSheet && group.specSheetId === row.id))
                    await QuestionCategoryGroupsService.updateQuestionCategoryGroup(group.id, { 
                        ...group, 
                        defaultGroup: !checked, 
                        specSheetId: removeSpecSheet && group.specSheetId === row.id ? undefined : group.specSheetId
                    });
            }
            await QuestionCategoryGroupsService.updateQuestionCategoryGroup(row.id, { ...row, defaultGroup: checked, specSheet: removeSpecSheet ? false : row.specSheet });
        } else {
            await QuestionCategoryGroupsService.updateQuestionCategoryGroup(row.id, { ...row, defaultGroup: checked });
        }

        refresh();
    };

    const specSheetChange = async (checked: boolean, row: QuestionCategoryGroupDTO) => {
        for (const group of groups) {
            if (group.specSheetId === row.id) await QuestionCategoryGroupsService.updateQuestionCategoryGroup(row.id, { ...row, specSheetId: undefined });
        }

        await QuestionCategoryGroupsService.updateQuestionCategoryGroup(row.id, { ...row, specSheet: checked });

        refresh();
    };

    const specSheetIdChange = async (value: string, row: QuestionCategoryGroupDTO) => {

        if (uuid.validate(value))
            await QuestionCategoryGroupsService.updateQuestionCategoryGroup(row.id, { ...row, specSheetId: value });

        refresh();
    };

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setLocalLoading(true);

        const selectedRows = apiRef.current.getSelectedRows();
        const hasDragged = selectedRows.has(params.row.id);
        if (hasDragged) {
            const selections = [...groups].filter((r) => selectedRows.has(r.id));
            const rowsClone = [...groups].filter((r) => !selections.map((v) => v.id).includes(r.id));

            rowsClone.splice(params.targetIndex, 0, ...(selections as QuestionCategoryGroupDTO[]));

            const newRows = await updateOrder(rowsClone.map((r) => r.id));

            apiRef.current.setSelectionModel([]);

            refresh();
        } else {
            const rowsClone = [...groups];
            const row = rowsClone.splice(params.oldIndex, 1)[0];
            rowsClone.splice(params.targetIndex, 0, row);

            const newRows = await updateOrder(rowsClone.map((r) => r.id));

            refresh();
        }
        setLocalLoading(false);
    };

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGridPro
                loading={loading || localLoading}
                rows={groups}
                apiRef={apiRef}
                columns={columns}
                pageSize={25}
                disableSelectionOnClick
                rowReordering
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={updateGroup}
                onRowOrderChange={handleRowOrderChange}
            />
        </Box>
    );
};

export default QuestionGroupsTable;
