import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { RoleRequest, RolesResponse, RoleResponse } from "@premier/models";

export class RolesService {
    public static async getRoles(): Promise<RolesResponse> {
        const [status, response] = await fetchApi<RolesResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Roles/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getRole(id: string): Promise<RoleResponse> {
        const [status, response] = await fetchApi<RoleResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Roles/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteRole(id: string): Promise<RoleResponse> {
        const [status, response] = await fetchApi<RoleResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Roles/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateRole(id: string, data: RoleRequest): Promise<RoleResponse> {
        const [status, response] = await fetchApi<RoleResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Roles/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createRole(data: RoleRequest): Promise<RoleResponse> {
        const [status, response] = await fetchApi<RoleResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Roles/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}