import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { BlueprintRequest, BlueprintResponse, BlueprintsResponse, ErrorResponse, FloorRequest, FloorResponse, FloorsResponse, LSPsResponse } from "@premier/models";

export class BlueprintsService {
    public static async getLSPs(id: string): Promise<LSPsResponse> {
        const [status, response] = await fetchApi<LSPsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Blueprints/${id}/LSPs`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateRotation(id: string, data: { rotation: number }): Promise<ErrorResponse> {
        const [status, response] = await fetchApi<ErrorResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Blueprints/${id}/rotate`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async getBlueprints(): Promise<BlueprintsResponse> {
        const [status, response] = await fetchApi<BlueprintsResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Blueprints/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getBlueprint(id: string): Promise<BlueprintResponse> {
        const [status, response] = await fetchApi<BlueprintResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Blueprints/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteBlueprint(id: string): Promise<BlueprintResponse> {
        const [status, response] = await fetchApi<BlueprintResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Blueprints/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateBlueprint(id: string, data: BlueprintRequest): Promise<BlueprintResponse> {
        const [status, response] = await fetchApi<BlueprintResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Blueprints/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createBlueprint(data: BlueprintRequest): Promise<BlueprintResponse> {
        const [status, response] = await fetchApi<BlueprintResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Blueprints/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}