import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { AnswerDTO, DeficiencyDTO, OrderRequest, QuestionDTO, QuestionRequest, QuestionResponse, QuestionsResponse } from "@premier/models";

export class QuestionsService {
    public static async getQuestions(): Promise<QuestionsResponse> {
        const [status, response] = await fetchApi<QuestionsResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Questions/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getQuestion(id: string): Promise<QuestionResponse> {
        const [status, response] = await fetchApi<QuestionResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Questions/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async getAnswers(id: string): Promise<AnswerDTO[]> {
        const [status, response] = await fetchApi<AnswerDTO[]>(ApiSettings, {
            method: "GET",
            path: `/api/Questions/${id}/Answers`,
            requiresAuth: true
        });

        return response;
    }

    public static async getDeficiencies(id: string): Promise<DeficiencyDTO[]> {
        const [status, response] = await fetchApi<DeficiencyDTO[]>(ApiSettings, {
            method: "GET",
            path: `/api/Questions/${id}/Deficiencies`,
            requiresAuth: true
        });

        return response;
    }

    public static async addDeficiency(questionId: string, defId: string): Promise<QuestionDTO> {
        const [status, response] = await fetchApi<QuestionDTO>(ApiSettings, {
            method: "POST",
            path: `/api/Questions/${questionId}/Deficiencies`,
            requiresAuth: true,
            body: { defId }
        });

        return response;
    }

    public static async updateOrder(data: OrderRequest): Promise<QuestionDTO[]> {
        const [status, response] = await fetchApi<QuestionDTO[]>(ApiSettings, {
            method: "PUT",
            path: `/api/Questions/order`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async deleteQuestion(id: string): Promise<QuestionResponse> {
        const [status, response] = await fetchApi<QuestionResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Questions/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateQuestion(id: string, data: QuestionDTO): Promise<QuestionResponse> {
        const [status, response] = await fetchApi<QuestionResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Questions/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createQuestion(data: QuestionDTO): Promise<QuestionResponse> {
        const [status, response] = await fetchApi<QuestionResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Questions/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}