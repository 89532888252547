import Typography from "@mui/material/Typography";
import { InspectionIssueDTO } from "@premier/models";
import { useEffect, useState } from "react";
import TodaysIssuesTable from "../components/tables/TodaysIssuesTable";
import { IssuesService } from "../lib/api/api/services/IssuesService";

export default function Home() {
    const [iss, setIss] = useState<InspectionIssueDTO[]>([]);

    useEffect(() => {
        const getIssues = async () => {
            const issues = await IssuesService.getTodaysIssues();

            setIss(issues);
        };

        getIssues();
    }, []);

    return (
        <>
        <Typography variant="h4">Today's Inspections</Typography>
        <TodaysIssuesTable issues={iss} refresh={async () => { return; }} />
        </>
    );
}