import { FetchOptions } from "./FetchOptions";
import { OpenApiSettings } from "./OpenApiSettings";
import rootStore from "../../../../stores/RootStore";
import decode from 'jwt-decode';

async function fetchApi<Type>(settings: OpenApiSettings, options: FetchOptions, isRefresh = false): Promise<[number, Type]> {
    let attempts = 0;
    do {
        if (isRefresh) {
            const tokenData = decode(settings.token);
            if (tokenData && (tokenData as any).exp < Date.now() / 1000) {
                console.log('Refresh token...');
                await rootStore.authStore.extendTokenAsync();
            }
        }

        const [status, json] = await fetchApiInternal<any>(settings, options);


        if (json.name === "JsonWebTokenError") {
            rootStore.authStore.logout();
        } else if (json.name === "TokenExpiredError") {
            await rootStore.authStore.extendTokenAsync();
        } else {
            return [status, json];
        }

        attempts++;
    } while (attempts < 5);

    // TODO handle error

    return [-1, {} as any];
};

async function fetchApiInternal<Type>(settings: OpenApiSettings, options: FetchOptions): Promise<[number, Type]> {
    const fetchOptions = buildOptions(settings, options);

    const response = await fetch(settings.baseUrl + options.path, fetchOptions);

    const json = await response.json();

    return [response.status, json];
}

const buildOptions = (settings: OpenApiSettings, options: FetchOptions): RequestInit => {
    const headers = new Headers();

    headers.set("x-api", "y");

    if (options.body !== undefined)
        headers.set("content-type", options.mediaType ?? "application/json");

    if (options.requiresAuth)
        headers.set('x-access-token', settings.token);

    return {
        method: options.method,
        headers: headers,
        body: JSON.stringify(options.body)
    };
}

export { fetchApi };