import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AnswerDTO, BuildingDTO, ClientDTO, InspectionReportOptions, QuestionCategoryGroupDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../../hooks/useStores";
import { AnswersService, InspectionTypesService } from "../../../../lib/api/api";
import { InspectionsService } from "../../../../lib/api/api/services/InspectionsService";

interface IProps {
    name: string;
}

const InspectionReport: FunctionComponent<IProps> = ({ name }) => {
    const { rootStore } = useStores();
    const params = useParams();

    const [wallRatings, setWallRatings] = useState<AnswerDTO[]>([]);
    const [buildings, setBuildings] = useState<BuildingDTO[]>([]);
    const [groups, setGroups] = useState<QuestionCategoryGroupDTO[]>([]);

    const [selWallRatings, setSelWallRatings] = useState<AnswerDTO[]>([]);
    const [selBuildings, setSelBuildings] = useState<BuildingDTO[]>([]);
    const [pinTextField, setPinTextField] = useState('');
    const [version, setVersion] = useState('1');
    const [sortField, setSortField] = useState('1');
    const [facType, setFacType] = useState('1');
    const [assetsPage, setAssetsPage] = useState('0');
    const [doorTypes, setDoorTypes] = useState<QuestionCategoryGroupDTO[]>([]);
    const [byBuilding, setByBuilding] = useState(false);

    const getWallRatings = async () => {
        const answers = await AnswersService.getAnswersForQuestion('0116f0f2-8518-4811-ba18-945d755f3862');

        if (answers.answers) setWallRatings(answers.answers);
    };

    const getGroups = async () => {
        const resp = await InspectionTypesService.getGroupsByJob(jobId);
        setGroups(resp.filter((g) => !g.defaultGroup && !g.specSheet));
    };

    const getBuildings = async () => {
        const resp = await InspectionsService.getBuildingsForJob(jobId);

        if (resp.buildings) setBuildings(resp.buildings);
    };

    const jobId = params.jobId as string;

    useEffect(() => {
        getWallRatings();
        getGroups();
        getBuildings();
    }, [jobId]);

    return (
        <Stack flex={1} alignItems={'center'}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={wallRatings}
                        onChange={(e, v) => setSelWallRatings(v)}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        getOptionLabel={(option) => option.name}
                        value={selWallRatings}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Wall Ratings"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="field-text-label">Pin Text Field</InputLabel>
                        <Select
                            labelId="field-text-label"
                            id="field-text-select"
                            value={pinTextField}
                            label="Pin Text Field"
                            onChange={(e) => setPinTextField(e.target.value)}
                        >
                            <MenuItem value='8fd841a2-90f6-4dd4-bc47-34a76b153e3e'>Door ID</MenuItem>
                            <MenuItem value='cc3d8fb4-907c-4c29-a923-82c7a76c09ff'>Alt ID</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField id="version" variant="outlined" type='number' label='Version' value={version} onChange={(e) => setVersion(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="field-sort-label">Sort Field</InputLabel>
                        <Select
                            labelId="field-sort-label"
                            id="field-sort-select"
                            value={sortField}
                            label="Sort Field"
                            onChange={(e) => setSortField(e.target.value)}
                        >
                            <MenuItem value='1'>Building / Floor</MenuItem>
                            <MenuItem value='2'>Door ID</MenuItem>
                            <MenuItem value='3'>Status (green then red)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <Autocomplete
                        multiple
                        id="buildings-outlined"
                        options={buildings}
                        onChange={(e, v) => setSelBuildings(v)}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        getOptionLabel={(option) => option.name}
                        value={selBuildings}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Buildings"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel style={{ width: '100%', height: '100%' }} control={<Checkbox value={byBuilding} onChange={(e) => setByBuilding(e.target.checked)} />} label="By Building" />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="fac-type-label">Facility Type</InputLabel>
                        <Select
                            labelId="fac-type-label"
                            id="fac-type-select"
                            value={facType}
                            label="Facility Type"
                            onChange={(e) => setFacType(e.target.value)}
                        >
                            <MenuItem value='1'>Healthcare</MenuItem>
                            <MenuItem value='2'>Non-Healthcare</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="assets-page-label">Assets Per Page</InputLabel>
                        <Select
                            labelId="assets-page-label"
                            id="assets-page-select"
                            value={assetsPage}
                            label="Assets Per Page"
                            onChange={(e) => setAssetsPage(e.target.value)}
                        >
                            <MenuItem value='0'>Default</MenuItem>
                            <MenuItem value='1'>1 Asset Per Page</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="doortypes-outlined"
                        options={groups}
                        onChange={(e, v) => setDoorTypes(v)}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        getOptionLabel={(option) => option.name}
                        value={doorTypes}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Door Types"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Button fullWidth onClick={() => {
                const options: InspectionReportOptions = {
                    ratings: selWallRatings.map((a) => a.id),
                    pinTextField,
                    version,
                    sortField,
                    byBuilding,
                    assetsPerPage: assetsPage,
                    facilityType: facType,
                    buildings: buildings.map((b) => b.id),
                    doorTypes: doorTypes.map((d) => d.id)
                };

                rootStore.wsService?.downloadReportAsync(name, jobId, options);
            }}>
                Download Report
            </Button>
        </Stack>
    );
}

export default InspectionReport;