import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { BuildingDTO, ClientDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BuildingModal from "../../../components/modals/BuildingModal";
import BuildingsTable from "../../../components/tables/BuildingTable";
import ClientsTable from "../../../components/tables/ClientTable";
import { ClientsService } from "../../../lib/api/api";

const Buildings: FunctionComponent = () => {
    const params = useParams();

    const [buildings, setBuildings] = useState<BuildingDTO[]>([]);
    const [editId, setEditId] = useState('');
    const [show, setShow] = useState(false);

    const clientId = params.clientId as string;

    const getBuildings = async () => {
        const buildingsResp = await ClientsService.getBuildings(clientId);

        if (buildingsResp.buildings) setBuildings(buildingsResp.buildings);
    };

    const onClose = () => {
        setShow(false);
        getBuildings();
    }

    useEffect(() => {
        getBuildings();
    }, [clientId]);

    return (
        <Stack width='100%' spacing={2}>
            <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={() => {
                setEditId('');
                setShow(true);
            }}>
                New Building
            </Button>
            <BuildingsTable buildings={buildings} onClick={(building) => {
                setEditId(building.id);
                setShow(true);
            }} />
            <BuildingModal buildingId={editId} isOpen={show} close={onClose} />
        </Stack>
    );
}

export default Buildings;