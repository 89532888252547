import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRowModel, GridRowOrderChangeParams, GridValueGetterParams, MuiEvent, useGridApiRef } from '@mui/x-data-grid-pro';
import { BlueprintDTO, ClientDTO, InspectionDTO } from '@premier/models';
import { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { ClientsService } from '../../../lib/api/api';
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import { InspectionsService } from '../../../lib/api/api/services/InspectionsService';
import ArchiveAction from '../ArchiveAction';
import DeleteAction from '../DeleteAction';
import PreviewImageAction from '../PreviewImageAction';
import { DateTime } from 'luxon';
import CopyAction from '../CopyAction';
import { toast } from 'react-toastify';
import LinkAction from '../LinkAction';
import CustomAction from '../CustomAction';
import rootStore from '../../../stores/RootStore';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

interface IProps {
    clientNumber: number;
    jobs: InspectionDTO[];
    refresh: () => Promise<void>;
}

const JobsTable: FunctionComponent<IProps> = ({ jobs, clientNumber, refresh }) => {
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(jobs);

    useEffect(() => setRows(jobs), [jobs]);

    const copyJob = async (id: string) => {
        const j = rows.find((j) => j.id === id);

        if (!j) {
            toast('Failed to copy job.', { type: toast.TYPE.ERROR });
            return;
        }

        const toastId = toast(`Copying job #${clientNumber}${String(j.inspectionNumber).padStart(4, '0')}`, { autoClose: false, isLoading: true, type: toast.TYPE.INFO });
        
        const res = await InspectionsService.duplicate(id);

        const s = res.success;

        toast.update(toastId, {
            render: s ? `Job copy succeeded.` : 'Job copy failed.',
            autoClose: 5000,
            type: s ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
            isLoading: false
        });

        if (s)
            refresh();
    }

    const columns: GridColDef[] = [
        {
            field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) =>
                <Stack direction='row'>
                    <CopyAction f={copyJob} id={params.row.id} />
                    <LinkAction text='View Job' link={`https://inspection.premierfire.app/InspectionHome?id=${params.row.id}`} />
                    <LinkAction text='View Reports' icon={<SummarizeOutlinedIcon />} internal={true} link={`/admin/reports/${params.row.id}`} />
                    <CustomAction text='Repair Door Count' f={(jobId: string) => rootStore.wsService?.repairDoorCountAsync(jobId)} id={params.row.id} />
                </Stack>
        },
        {
            field: 'archived', headerName: 'Archived', flex: 0.25, renderCell: (params) => <ArchiveAction isArchived={params.row.archived} />
        },
        {
            field: 'fullJobNumber',
            headerName: 'Job Number',
            flex: 1,
            valueGetter: (params) => `${clientNumber}${String(params.row.inspectionNumber).padStart(4, '0')}`
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 0.5,
            valueGetter: (params) => DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATETIME_MED)
        },
        {
            field: 'inspectionNumber',
            headerName: 'Job Specific Number',
            flex: 0.5
        },
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={columns}
                pageSize={25}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                onCellClick={async (params, event, details) => {
                    if (params.field === 'archived') {
                        apiRef.current.updateRows([{ id: params.row.id, archived: !params.row.archived }]);
                        const jResp = await InspectionsService.updateInspection(params.row.id, {
                            archived: !params.row.archived
                        });
                    }
                }}
            />
        </Box>
    );
};

export default JobsTable;
