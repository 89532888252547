import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { AccountCreateRequest, AccountUpdateRequest, AccountResponse, AccountsResponse, MyAccountResponse } from "@premier/models";

export class AccountsService {
    public static async getMe(): Promise<MyAccountResponse> {
        const [status, response] = await fetchApi<MyAccountResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Accounts/me",
            requiresAuth: true
        });

        return response;
    }

    public static async getAccounts(): Promise<AccountsResponse> {
        const [status, response] = await fetchApi<AccountsResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Accounts/all",
            requiresAuth: true
        });

        return response;
    }

    public static async resetPassword(id: string): Promise<boolean> {
        const [status, response] = await fetchApi<boolean>(ApiSettings, {
            method: "POST",
            path: `/api/Accounts/${id}/resetPassword`,
            requiresAuth: true
        });

        return response;
    }

    public static async toggleDisable(id: string): Promise<boolean> {
        const [status, response] = await fetchApi<boolean>(ApiSettings, {
            method: "POST",
            path: `/api/Accounts/${id}/disable`,
            requiresAuth: true
        });

        return response;
    }

    public static async searchTechs(searchTerm: string): Promise<AccountsResponse> {
        const [status, response] = await fetchApi<AccountsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Accounts/Techs/Search/${searchTerm}`,
            requiresAuth: true
        });

        return response;
    }

    public static async getAccount(id: string): Promise<AccountResponse> {
        const [status, response] = await fetchApi<AccountResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Accounts/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteAccount(id: string): Promise<AccountResponse> {
        const [status, response] = await fetchApi<AccountResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Accounts/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateAccount(id: string, data: AccountUpdateRequest): Promise<AccountResponse> {
        const [status, response] = await fetchApi<AccountResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Accounts/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createAccount(data: AccountUpdateRequest): Promise<AccountResponse> {
        const [status, response] = await fetchApi<AccountResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Accounts/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}