import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ClientDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ClientsTable from "../../../components/tables/ClientTable";
import { ClientsService } from "../../../lib/api/api";

const Survey: FunctionComponent = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const surveyId = params.surveyId as string;

    const lastLocation = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    return (
            <Stack marginTop={2} direction={'row'} flex={1} spacing={surveyId !== 'new' ? 2 : 0}>
                <List sx={{ bgcolor: 'background.paper', display: surveyId !== 'new' ? 'block' : 'none' }}>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'edit'}
                        onClick={() => navigate('edit')}
                    >
                        <ListItemText primary='Survey' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'groups'}
                        onClick={() => navigate('groups')}
                    >
                        <ListItemText primary='Question Groups' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'categories'}
                        onClick={() => navigate('categories')}
                    >
                        <ListItemText primary='Question Categories' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'questions'}
                        onClick={() => navigate('questions')}
                    >
                        <ListItemText primary='Questions' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'deficiencies'}
                        onClick={() => navigate('deficiencies')}
                    >
                        <ListItemText primary='Deficiencies' />
                    </ListItemButton>
                </List>
                <Outlet key={surveyId} />
            </Stack>
    );
}

export default Survey;