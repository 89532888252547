import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { AccountsService } from '../../../../lib/api/api';

interface IProps {
    uploaderId: string;
}

const BlueprintUploader: FunctionComponent<IProps> = ({ uploaderId }) => {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('N/A');

    useEffect(() => {
        const getUploaderName = async () => {
            const account = await AccountsService.getAccount(uploaderId);
            
            if (account.account) setName(`${account.account.firstName} ${account.account.lastName}`);

            setLoading(false);
        };

        setLoading(true);
        getUploaderName();
    }, [uploaderId]);

    if (loading)
        return <CircularProgress />;

    return <Typography>{name}</Typography>;
};

export default BlueprintUploader;