import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { FunctionComponent } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const Client: FunctionComponent = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const lastLocation = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const jobId = params.jobId as string;

    return (
            <Stack marginTop={2} direction={'row'}>
                <List sx={{ bgcolor: 'background.paper' }}>
                    <ListItemButton
                        disableGutters
                        selected={lastLocation === 'summary'}
                        sx={{ padding: 1 }}
                        onClick={() => navigate('summary')}
                    >
                        <ListItemText primary='Summary Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'tm-report'}
                        onClick={() => navigate('tm-report')}
                    >
                        <ListItemText primary='T&M Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'parts'}
                        onClick={() => navigate('parts')}
                    >
                        <ListItemText primary='Parts Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'internal'}
                        onClick={() => navigate('internal')}
                    >
                        <ListItemText primary='Internal Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'facility-notes'}
                        onClick={() => navigate('facility-notes')}
                    >
                        <ListItemText primary='Facility Notes Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'door-order'}
                        onClick={() => navigate('door-order')}
                    >
                        <ListItemText primary='Door Order Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'door-finish'}
                        onClick={() => navigate('door-finish')}
                    >
                        <ListItemText primary='Door Finish Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'door-spec'}
                        onClick={() => navigate('door-spec')}
                    >
                        <ListItemText primary='Door Specs Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'vcu'}
                        onClick={() => navigate('vcu')}
                    >
                        <ListItemText primary='VCU Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'labeled-lsp'}
                        onClick={() => navigate('labeled-lsp')}
                    >
                        <ListItemText primary='Labeled LSP Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'counted-lsp'}
                        onClick={() => navigate('counted-lsp')}
                    >
                        <ListItemText primary='Counted LSP Report' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'inspection'}
                        onClick={() => navigate('inspection')}
                    >
                        <ListItemText primary='Inspection Report' />
                    </ListItemButton>
                </List>
                <Outlet key={jobId} />
            </Stack>
    );
}

export default Client;