import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { BlueprintDTO, ClientDTO, JobDTO, QuestionCategoryDTO, QuestionCategoryGroupDTO } from "@premier/models";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BlueprintsTable from '../../../components/tables/BlueprintTable';
import ClientsTable from "../../../components/tables/ClientTable";
import { useStores } from '../../../hooks/useStores';
import { ClientsService, InspectionTypesService, QuestionCategoriesService } from "../../../lib/api/api";
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import { JobsService } from '../../../lib/api/api/services/JobsService';
import { PDFToImage } from '../../../utils/PDF/PDFToImage';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { type } from 'os';
import { QuestionCategoryGroupsService } from '../../../lib/api/api/services/QuestionCategoryGroupsService';
import QuestionCategoryTable from '../../../components/tables/QuestionCategoryTable';

const QuestionCategories: FunctionComponent = () => {
    const { rootStore } = useStores();
    const params = useParams();
    const navigate = useNavigate();

    const [groups, setGroups] = useState<QuestionCategoryGroupDTO[]>([]);
    const [categories, setCategories] = useState<QuestionCategoryDTO[]>([]);
    const [currentGroup, setCurrentGroup] = useState('');

    const surveyId = params.surveyId as string;

    const getGroups = async () => {
        const resp = await InspectionTypesService.getGroups(surveyId);

        if (resp) {
            setGroups(resp);
            setCurrentGroup(resp[0].id!);
        }

        getCategories();
    };

    const getCategories = async () => {
        const resp = await InspectionTypesService.getCategories(surveyId);

        if (resp) setCategories(resp);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setCurrentGroup(event.target.value as string);
    };

    useEffect(() => {
        getGroups();
    }, [surveyId]);

    const createNew = async () => {
        await QuestionCategoriesService.createQuestionCategory({
            id: '',
            name: 'New Category',
            description: '',
            order: 0,
            tenantId: '',
            groupId: currentGroup,
            inspectionTypeId: surveyId,
            Questions:[]
        });

        getCategories();
    }

    const filtered = categories.filter((b) => b.groupId === currentGroup);

    return (
        <Stack width='100%' flex={1} spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="group-select-label">Question Group</InputLabel>
                <Select
                    labelId="group-select-label"
                    id="group-select"
                    value={currentGroup}
                    label="Job Type"
                    onChange={handleChange}
                >
                    {groups.map((j) => <MenuItem key={j.id} value={j.id}>{j.name}</MenuItem>)}
                </Select>
            </FormControl>
            <Button variant="outlined" onClick={createNew}>
                New Category
            </Button>
            <QuestionCategoryTable categories={filtered} surveyId={surveyId} refresh={getCategories} />
        </Stack>
    );
}

export default QuestionCategories;