import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { useStores } from '../../hooks/useStores';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SecurityUpdateOutlinedIcon from '@mui/icons-material/SecurityUpdateOutlined';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { SocketClientInfoDto } from '@premier/models';
import UserInfoModal from '../modals/UserInfoModal';
import { UpdateService } from '../../lib/api/api/services/UpdateService';
import QRCode from 'react-qr-code';
import { ApiUtils } from '../../lib/api/api/core/Utils';

interface IProps {
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const MainNavigation: FunctionComponent<IProps> = ({ }) => {
  const { rootStore } = useStores();

  const navigate = useNavigate();

  const anchorRef = React.useRef(null);
  const anchorInfoRef = React.useRef(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [info, setInfoOpen] = React.useState(false);
  const [connectedClients, setConnectedClients] = useState<SocketClientInfoDto[]>([]);
  const [latestExpoInfo, setLatestExpoInfo] = useState<{
    id: string;
    majorVersion: number;
    minorVersion: number;
    buildNumber: number;
  }>();

  const [clientInfoId, setClientInfoId] = useState('');
  const [showInfoModal, setShowInfoModal] = useState(false);

  const id = open ? 'connected-clients' : undefined;
  const infoId = info ? 'update-info' : undefined;

  return (
    <>
      <Stack direction='row' justifyContent='space-around'>
        <Button
          onClick={() => navigate('/')}
        >
          Home
        </Button>
        <Button
          onClick={() => navigate('/admin/accounts')}
        >
          Accounts
        </Button>
        <Button
          onClick={() => navigate('/admin/surveys')}
        >
          Surveys
        </Button>
        <Button
          onClick={() => navigate('/admin/clients')}
        >
          Clients
        </Button>
        <Button
          onClick={() => navigate('/admin/jobs')}
        >
          Jobs
        </Button>
        <Button
          onClick={() => navigate('/admin/reports')}
        >
          Reports
        </Button>
        <Button
          onClick={rootStore.authStore.logout}
        >
          Logout
        </Button>
        <Tooltip title='Latest App Version'>
          <IconButton onClick={async () => {
            const info = await UpdateService.getLatest('internal');
            setLatestExpoInfo(info);
            setInfoOpen(true);
          }} ref={anchorInfoRef}>
            <SecurityUpdateOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Connected Users'>
          <IconButton onClick={async () => {
            const clients = await rootStore.wsService?.getConnectedClients() ?? [];
            setConnectedClients(clients);
            setOpen(true);
          }} ref={anchorRef}>
            <GroupOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Popper
        id={infoId}
        open={info}
        anchorEl={anchorInfoRef.current}
        placement="bottom"
        disablePortal={false}
        style={{
          zIndex: 999
        }}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'arrow',
            enabled: false,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={() => setInfoOpen(false)}>
          <Paper>
            <DialogTitle>Update Info</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Scan the code to download version ${latestExpoInfo?.majorVersion}.${latestExpoInfo?.minorVersion}.${latestExpoInfo?.buildNumber}`}
              </DialogContentText>
              <QRCode value={`itms-services://?action=download-manifest&url=${ApiUtils.getApiHttps()}/api/Update/download/${latestExpoInfo?.id}`} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setInfoOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        disablePortal={false}
        style={{
          zIndex: 999
        }}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'arrow',
            enabled: false,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper>
            <DialogTitle>Connected Clients</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Note: some clients may be in offline mode.
              </DialogContentText>
              <List sx={{ maxHeight: 300, overflow: 'auto', width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {connectedClients.map((c) => {
                  return (
                    <ListItemButton onClick={() => {
                      setOpen(false);
                      setClientInfoId(c.sId);
                      setShowInfoModal(true);
                    }} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar {...stringAvatar(c.name)} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={c.name}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Status:
                            </Typography>
                            {` ${c.status}`}
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {showInfoModal &&
        <UserInfoModal socketId={clientInfoId} isOpen={showInfoModal} close={() => setShowInfoModal(false)} />
      }
    </>
  );
}

export default MainNavigation;
