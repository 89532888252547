import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AnswerDTO, BuildingDTO, ClientDTO, InspectionReportOptions, LSPReportOptions, QuestionCategoryGroupDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../../hooks/useStores";
import { AnswersService, InspectionTypesService } from "../../../../lib/api/api";
import { InspectionsService } from "../../../../lib/api/api/services/InspectionsService";

interface IProps {
    name: string;
}

const LSPReport: FunctionComponent<IProps> = ({ name }) => {
    const { rootStore } = useStores();
    const params = useParams();

    const [wallRatings, setWallRatings] = useState<AnswerDTO[]>([]);
    const [buildings, setBuildings] = useState<BuildingDTO[]>([]);
    const [groups, setGroups] = useState<QuestionCategoryGroupDTO[]>([]);

    const [fontSize, setFontSize] = useState('32');
    const [pinSize, setPinSize] = useState('0');

    const getWallRatings = async () => {
        const answers = await AnswersService.getAnswersForQuestion('0116f0f2-8518-4811-ba18-945d755f3862');

        if (answers.answers) setWallRatings(answers.answers);
    };

    const getGroups = async () => {
        const resp = await InspectionTypesService.getGroupsByJob(jobId);
        setGroups(resp.filter((g) => !g.defaultGroup && !g.specSheet));
    };

    const getBuildings = async () => {
        const resp = await InspectionsService.getBuildingsForJob(jobId);

        if (resp.buildings) setBuildings(resp.buildings);
    };

    const jobId = params.jobId as string;

    useEffect(() => {
        getWallRatings();
        getGroups();
        getBuildings();
    }, [jobId]);

    return (
        <Stack flex={1} alignItems={'center'}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="font-size-label">Font Size</InputLabel>
                        <Select
                            labelId="font-size-label"
                            id="font-size-select"
                            value={fontSize}
                            label="Font Size"
                            onChange={(e) => setFontSize(e.target.value)}
                        >
                            <MenuItem value='8'>8pt</MenuItem>
                            <MenuItem value='10'>10pt</MenuItem>
                            <MenuItem value='12'>12pt</MenuItem>
                            <MenuItem value='14'>14pt</MenuItem>
                            <MenuItem value='16'>16pt</MenuItem>
                            <MenuItem value='32'>32pt</MenuItem>
                            <MenuItem value='64'>64pt</MenuItem>
                            <MenuItem value='128'>128pt</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="pin-size-label">Pin Size</InputLabel>
                        <Select
                            labelId="pin-size-label"
                            id="pin-size-select"
                            value={pinSize}
                            label="Pon Size"
                            onChange={(e) => setPinSize(e.target.value)}
                        >
                            <MenuItem value='0'>Default</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Button fullWidth onClick={() => {
                const options: LSPReportOptions = {
                    fontSize: parseInt(fontSize),
                    pinSize: parseInt(pinSize)
                };

                rootStore.wsService?.downloadReportAsync(name, jobId, options);
            }}>
                Download Report
            </Button>
        </Stack>
    );
}

export default LSPReport;