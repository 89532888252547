import { ClientDTO } from "@premier/models";
import { FunctionComponent } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IProps {
    onClick: () => void;
}

const EditAction: FunctionComponent<IProps> = ({ onClick }) => {
    const navigate = useNavigate();

    return (
        <Stack width='100%' alignItems='center'>
            <IconButton onClick={onClick}>
                <EditOutlinedIcon />
            </IconButton>
        </Stack>

    );
};

export default EditAction;