import { FunctionComponent } from "react";
import Stack from "@mui/material/Stack";
import { IconButton, Tooltip } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

interface IProps {
    f: (id: string) => void;
    id: string;
}

const DeleteAction: FunctionComponent<IProps> = ({ f, id }) => {
    return (
        <Stack width='100%' alignItems='center'>
            <Tooltip title="Delete Blueprint">
                <IconButton onClick={() => f(id)}>
                    <DeleteForeverOutlinedIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export default DeleteAction;