import ImageOutlined from '@mui/icons-material/ImageOutlined';
import HideImageOutlined from '@mui/icons-material/HideImageOutlined';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRowModel, GridRowOrderChangeParams, GridValueGetterParams, MuiEvent, useGridApiRef } from '@mui/x-data-grid-pro';
import { BlueprintDTO, ClientDTO, QuestionDTO } from '@premier/models';
import { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { ClientsService, QuestionsService } from '../../../lib/api/api';
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import ArchiveAction from '../ArchiveAction';
import DeleteAction from '../DeleteAction';
import PreviewImageAction from '../PreviewImageAction';
import BlueprintUploader from '../LazyCells/BlueprintUploader';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import { SelectionType } from '../../../lib/api/api/enums/SelectionType';
import MenuItem from '@mui/material/MenuItem';
import EditAnswersAction from '../EditAnswersAction';

interface IProps {
    surveyId: string;
    questions: QuestionDTO[];
    refresh: () => Promise<void>;
    showAnswersModal: (id: string, name: string) => void;
    showDefsModal: (id: string, name: string) => void;
}

const update = async (newRow: GridRowModel<QuestionDTO>) => {
    const resp = await QuestionsService.updateQuestion(newRow.id, {...newRow, QuestionExtraInfos: [], Answers: [], Deficiencies: [], inspectionTypeId: undefined});
    return resp.question!;
}

const updateOrder = async (clientId: string, ids: string[]) => {
    const resp = await QuestionsService.updateOrder({order: ids});
    return resp;
}

const QuestionsTable: FunctionComponent<IProps> = ({ questions, surveyId, refresh, showAnswersModal, showDefsModal }) => {
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(questions);

    useEffect(() => setRows(questions), [questions]);

    const typeHasAnswers = (type: string) => {
        switch (type) {
            case SelectionType.MULTI_SELECT:
            case SelectionType.SINGLE_SELECT:
                return true;
            default:
                return false;
        };
    }

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setLoading(true);

        const selectedRows = apiRef.current.getSelectedRows();
        const hasDragged = selectedRows.has(params.row.id);
        if (hasDragged) {
            const selections = [...rows].filter((r) => selectedRows.has(r.id));
            const rowsClone = [...rows].filter((r) => !selections.map((v) => v.id).includes(r.id));

            rowsClone.splice(params.targetIndex, 0, ...(selections as QuestionDTO[]));

            const newRows = await updateOrder(surveyId, rowsClone.map((r) => r.id));

            apiRef.current.setSelectionModel([]);

            setRows(newRows);
        } else {
            const rowsClone = [...rows];
            const row = rowsClone.splice(params.oldIndex, 1)[0];
            rowsClone.splice(params.targetIndex, 0, row);

            const newRows = await updateOrder(surveyId, rowsClone.map((r) => r.id));

            setRows(newRows);
        }
        setLoading(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) =>
                <Stack direction='row'>
                    {typeHasAnswers(params.row.selectionType) && <EditAnswersAction onClick={() => showAnswersModal(params.row.id, params.row.name)} />}
                    <EditAnswersAction onClick={() => showDefsModal(params.row.id, params.row.name)} />
                </Stack>
        },
        {
            field: 'hidden', headerName: 'Hidden', flex: 0.25, renderCell: (params) =>
                <ArchiveAction
                    isArchived={params.row.hidden}
                    archivedMessage='Un-archive Question'
                    message='Archive Question'
                />
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            editable: true
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 0.5,
            editable: true
        },
        {
            field: 'reportVerbage',
            headerName: 'Report Verbage',
            flex: 0.5,
            editable: true
        },
        {
            field: 'nfpaCodes',
            headerName: 'NFPA Codes',
            flex: 0.5
        },
        {
            field: 'selectionType',
            headerName: 'Question Type',
            flex: 0.25,
            renderCell: (params) =>
                <Select
                    fullWidth
                    id={`selectionType-select-${params.row.id}`}
                    value={params.row.selectionType}
                    label="Selection Type"
                    onChange={(e) => selectionTypeChange(e.target.value, params.row)}
                >
                    {Object.values(SelectionType).map((g) => <MenuItem key={`selectionTypeItem-${g}-${params.row.id}`} value={g}>{g}</MenuItem>)}
                </Select>
        },
        {
            field: 'required',
            headerName: 'Required Answer?',
            renderCell: (params) => <Checkbox checked={params.row.required} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.2
        },
        {
            field: 'copyOnNew',
            headerName: 'Duplicate Answer?',
            renderCell: (params) => <Checkbox checked={params.row.copyOnNew} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.2
        },
        {
            field: 'isLabelDeficiency',
            headerName: 'Label Deficiency?',
            renderCell: (params) => <Checkbox checked={params.row.isLabelDeficiency} onChange={(e) => checkedChange(e.target.checked, params.field, params.row)} />,
            flex: 0.2
        }
    ];

    const checkedChange = async (checked: boolean, field: string, row: QuestionDTO) => {
        await QuestionsService.updateQuestion(row.id, { ...row, [field]: checked, QuestionExtraInfos: [], Answers: [], Deficiencies: [], inspectionTypeId: undefined });

        refresh();
    }

    const selectionTypeChange = async (value: string, row: QuestionDTO) => {
        await QuestionsService.updateQuestion(row.id, { ...row, selectionType: value, QuestionExtraInfos: [], Answers: [], Deficiencies: [], inspectionTypeId: undefined });

        refresh();
    }

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={columns}
                pageSize={25}
                experimentalFeatures={{ newEditingApi: true }}
                rowReordering
                processRowUpdate={update}
                onRowOrderChange={handleRowOrderChange}
                onCellClick={async (params, event, details) => {
                    if (params.field === 'hidden') {
                        const bResp = await QuestionsService.updateQuestion(params.row.id, {...params.row, hidden: !params.row.hidden, QuestionExtraInfos: [], Answers: [], Deficiencies: [], inspectionTypeId: undefined });
                        refresh();
                    }
                }}
            />
        </Box>
    );
};

export default QuestionsTable;
