import ImageOutlined from '@mui/icons-material/ImageOutlined';
import HideImageOutlined from '@mui/icons-material/HideImageOutlined';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRowModel, GridRowOrderChangeParams, GridValueGetterParams, MuiEvent, useGridApiRef } from '@mui/x-data-grid-pro';
import { BlueprintDTO, ClientDTO } from '@premier/models';
import { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { ClientsService } from '../../../lib/api/api';
import { BlueprintsService } from '../../../lib/api/api/services/BlueprintsService';
import ArchiveAction from '../ArchiveAction';
import DeleteAction from '../DeleteAction';
import PreviewImageAction from '../PreviewImageAction';
import BlueprintUploader from '../LazyCells/BlueprintUploader';
import { DateTime } from 'luxon';

interface IProps {
    clientId: string;
    blueprints: BlueprintDTO[];
    refresh: () => Promise<void>;
}

const updateBlueprint = async (newRow: GridRowModel<BlueprintDTO>) => {
    const bResp = await BlueprintsService.updateBlueprint(newRow.id, {
        name: newRow.name,
        floorNumber: newRow.floorNumber,
        description: newRow.description ?? undefined
    });
    return bResp.blueprint!;
}

const updateOrder = async (clientId: string, ids: string[]) => {
    const bResp = await ClientsService.updateBlueprintsOrder(clientId, ids);
    return bResp.blueprints!;
}

const BlueprintsTable: FunctionComponent<IProps> = ({ blueprints, clientId, refresh }) => {
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(blueprints);

    useEffect(() => setRows(blueprints), [blueprints]);

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setLoading(true);

        const selectedRows = apiRef.current.getSelectedRows();
        const hasDragged = selectedRows.has(params.row.id);
        if (hasDragged) {
            const selections = [...rows].filter((r) => selectedRows.has(r.id));
            const rowsClone = [...rows].filter((r) => !selections.map((v) => v.id).includes(r.id));

            rowsClone.splice(params.targetIndex, 0, ...(selections as BlueprintDTO[]));

            const newRows = await updateOrder(clientId, rowsClone.map((r) => r.id));

            apiRef.current.setSelectionModel([]);

            setRows(newRows);
        } else {
            const rowsClone = [...rows];
            const row = rowsClone.splice(params.oldIndex, 1)[0];
            rowsClone.splice(params.targetIndex, 0, row);

            const newRows = await updateOrder(clientId, rowsClone.map((r) => r.id));

            setRows(newRows);
        }
        setLoading(false);
    };

    const deleteBlueprint = async (id: string) => {
        await BlueprintsService.deleteBlueprint(id);
        refresh();
    }

    const columns: GridColDef[] = [
        {
            field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) =>
                <Stack direction='row'>
                    <DeleteAction f={deleteBlueprint} id={params.row.id} />
                    <PreviewImageAction row={params.row} />
                </Stack>
        },
        {
            field: 'archived', headerName: 'Archived', flex: 0.25, renderCell: (params) =>
                <ArchiveAction
                    isArchived={params.row.archived}
                    archivedMessage='Un-archive Blueprint'
                    message='Archive Blueprint'
                />
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            editable: true
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 0.5,
            editable: true
        },
        {
            field: 'floorNumber',
            headerName: 'Floor',
            flex: 0.25,
            editable: true
        },
        {
            field: 'rotation',
            headerName: 'Rotation',
            flex: 0.25
        },
        {
            field: 'uploadedById',
            headerName: 'Uploaded By',
            renderCell: (params) => <BlueprintUploader uploaderId={params.row.uploadedById} />,
            flex: 0.5
        },
        {
            field: 'createdAt',
            headerName: 'Uploaded On',
            renderCell: (params) => DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATETIME_MED),
            flex: 0.5
        }
    ];

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={columns}
                pageSize={25}
                experimentalFeatures={{ newEditingApi: true }}
                rowReordering
                processRowUpdate={updateBlueprint}
                onRowOrderChange={handleRowOrderChange}
                onCellClick={async (params, event, details) => {
                    if (params.field === 'archived') {
                        apiRef.current.updateRows([{ id: params.row.id, archived: !params.row.archived }]);
                        const bResp = await BlueprintsService.updateBlueprint(params.row.id, {
                            name: params.row.name,
                            floorNumber: params.row.floorNumber,
                            description: params.row.description ?? undefined,
                            archived: !params.row.archived as boolean
                        });
                    }
                }}
            />
        </Box>
    );
};

export default BlueprintsTable;
