import { ApiUtils } from "./Utils";

export interface OpenApiSettings {
    baseUrl: string;
    token: string;
}

const ApiSettings: OpenApiSettings = {
    baseUrl: ApiUtils.getApiHttps(),
    token: ''
}
export { ApiSettings }