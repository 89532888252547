import { FunctionComponent } from "react";
import Stack from "@mui/material/Stack";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface IProps {
    isArchived: boolean;
    archivedMessage?: string;
    message?: string;
    archiveIcon?: JSX.Element;
    icon?: JSX.Element;
    onClick?: () => void;
}

const ArchiveAction: FunctionComponent<IProps> = ({ onClick, isArchived, archivedMessage = 'Un-archive', message = 'Archive', archiveIcon = <VisibilityOffOutlinedIcon />, icon = <VisibilityOutlinedIcon />  }) => {
    return (
        <Stack width='100%' alignItems='center'>
            <Tooltip title={isArchived ? archivedMessage : message}>
                <IconButton onClick={onClick}>
                    {isArchived ?
                        archiveIcon : icon
                    }
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export default ArchiveAction;