import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { ClientDTO, InspectionDTO, JobDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import JobsTable from "../../../components/tables/JobsTable";
import { ClientsService } from "../../../lib/api/api";
import { InspectionsService } from "../../../lib/api/api/services/InspectionsService";
import { JobsService } from "../../../lib/api/api/services/JobsService";
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { toast } from "react-toastify";

const Jobs: FunctionComponent = () => {
    const params = useParams();

    const [jobTypes, setJobTypes] = useState<JobDTO[]>([]);
    const [jobs, setJobs] = useState<InspectionDTO[]>([]);
    const [client, setClient] = useState<ClientDTO>();
    const [currentJobType, setCurrentJobType] = useState('');

    const clientId = params.clientId as string;

    const getJobTypes = async () => {
        const jobTypes = await JobsService.getJobs();

        if (jobTypes.jobs) {
            setJobTypes(jobTypes.jobs);
            setCurrentJobType(jobTypes.jobs[0].id!);
            getJobs(jobTypes.jobs[0].id!);
        }
    };

    const getClient = async () => {
        const client = await ClientsService.getClient(clientId);

        if (client.client) setClient(client.client);
    };

    const getJobs = async (jobType?: string) => {
        const jobs = await InspectionsService.getInspectionsByClientJob(clientId, jobType ?? currentJobType);

        if (jobs.inspections) setJobs(jobs.inspections);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setCurrentJobType(event.target.value as string);
    };

    const createInspection = async () => {

        const toastId = toast(`Creating new job...`, { autoClose: false, type: toast.TYPE.INFO, isLoading: true });

        const resp = await InspectionsService.createNewInspection(clientId, currentJobType);

        const s = !!!resp.error;

        toast.update(toastId, {
            render: s ? `Job creation succeeded.` : 'Job creation failed.',
            autoClose: 5000,
            type: s ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
            isLoading: false
        });

        getJobs();
    }

    useEffect(() => {
        getClient();
        getJobTypes();
    }, [clientId]);

    const filteredJobs = jobs.filter((j) => j.jobId === currentJobType);

    return (
        <Stack width='100%' spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="jobtype-select-label">Job Type</InputLabel>
                <Select
                    labelId="jobtype-select-label"
                    id="jobtype-select"
                    value={currentJobType}
                    label="Job Type"
                    onChange={handleChange}
                >
                    {jobTypes.map((j) => <MenuItem key={j.id} value={j.id}>{j.name}</MenuItem>)}
                </Select>
            </FormControl>
            {currentJobType &&
                <Button variant="outlined" startIcon={<WorkOutlineOutlinedIcon />} onClick={createInspection}>
                    New Job
                </Button>
            }
            {currentJobType && <JobsTable jobs={filteredJobs} clientNumber={client?.clientNumber ?? -1} refresh={getJobs} />}
        </Stack>
    );
}

export default Jobs;