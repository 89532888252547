import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { PartRequest, PartResponse, PartsResponse } from "@premier/models";

export class UpdateService {

    public static async getLatest(channel: string): Promise<{ id: string, majorVersion: number, minorVersion: number, buildNumber: number}> {
        const [status, response] = await fetchApi<{ id: string, majorVersion: number, minorVersion: number, buildNumber: number}>(ApiSettings, {
            method: "GET",
            path: `/api/Update/${channel}/latest`,
            requiresAuth: true
        });

        return response;
    }
}