import Box from '@mui/material/Box';
import { DataGridPro, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { BuildingDTO, ClientDTO } from '@premier/models';
import { FunctionComponent } from 'react';
import EditAction from '../EditAction';

interface IProps {
  buildings: BuildingDTO[];
  onClick: (building: BuildingDTO) => void;
}

const BuildingsTable: FunctionComponent<IProps> = ({ buildings, onClick }) => {
  const columns: GridColDef[] = [
    { field: 'action', headerName: 'Actions', flex: 0.25, renderCell: (params) => <EditAction onClick={() => onClick(params.row as BuildingDTO)} /> },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.25,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    }
  ];

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGridPro
        rows={buildings}
        columns={columns}
        pageSize={25}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};

export default BuildingsTable;
