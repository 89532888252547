import { Button, ClickAwayListener, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Popper } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { InspectionDTO } from "@premier/models";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { InspectionsService } from "../../../lib/api/api/services/InspectionsService";

interface SearchedJob {
    id: string;
    displayName: string;
    hits: number;
}

const Clients: FunctionComponent = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [jobs, setJobs] = useState<InspectionDTO[]>([]);

    const [searchTerm, setSearchTerm] = useState('');
    const anchorEl = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const getJobs = async () => {
            const jobs = await InspectionsService.getInspections();

            if (jobs.inspections) {
                setJobs(jobs.inspections);

                if (params.jobId) {
                    const j = jobs.inspections.find((j) => j.id === params.jobId);

                    if (j)
                        setSearchTerm(j.Client?.clientNumber + String(j.inspectionNumber).padStart(4, '0') + ' - ' + j.Client?.name + ' - ' + j.Client?.address + ', ' + j.Client?.city + ', ' + j.Client?.state + ' ' + j.Client?.zip)
                }
            }
        };

        getJobs();
    }, []);

    const handleFocus = () => {
        setOpen(true);
    }

    const handleBlur = () => {
        setOpen(false);
    }

    const handleClientClick = (job: SearchedJob) => {
        setOpen(false);
        setSearchTerm(job.displayName);
        navigate(`${job.id}`);
    }

    const splitSearch = searchTerm.trim().split(' ');

    const SearchedJobs = (jobs.map((j) => {
        const displayName = j.Client?.clientNumber + String(j.inspectionNumber).padStart(4, '0') + ' - ' + j.Client?.name + ' - ' + j.Client?.address + ', ' + j.Client?.city + ', ' + j.Client?.state + ' ' + j.Client?.zip;

        const hits = splitSearch.map((v) => displayName.toLowerCase().includes(v.toLowerCase())).filter((v) => v === true).length;

        return {
            id: j.id,
            displayName: displayName,
            hits: hits
        };
    }) as SearchedJob[]).filter((c) => c.hits > 0 || splitSearch.length === 0).sort((a, z) => z.hits - a.hits);

    return (
        <Stack margin={2}>
            <ClickAwayListener onClickAway={handleBlur}>
                <Stack>
                    <Stack spacing={2} direction='row'>
                        <TextField
                            ref={anchorEl}
                            id="client-search"
                            label="Search"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onFocus={handleFocus}
                            sx={{ flex: 1 }}
                            autoComplete='off'
                        />
                    </Stack>
                    {open &&
                        <Popper
                            placement="bottom"
                            anchorEl={anchorEl.current}
                            open={open}
                            sx={{ width: '100%', zIndex: 9999 }}
                        >
                            <Paper
                                sx={{
                                    margin: 2,
                                    marginTop: 1,
                                    padding: 1
                                }}
                            >
                                <Stack maxHeight={200} overflow='auto'>
                                    <List sx={{ bgcolor: 'background.paper' }}>
                                        {SearchedJobs.map((value) => (
                                            <ListItemButton
                                                key={value.id + value.displayName}
                                                disableGutters
                                                sx={{ padding: 1 }}
                                                onClick={() => handleClientClick(value)}
                                            >
                                                <ListItemText primary={value.displayName} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Stack>
                            </Paper>
                        </Popper>
                    }
                </Stack>
            </ClickAwayListener>
            <Outlet />
        </Stack>
    );
}

export default Clients;