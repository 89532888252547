import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ClientDTO } from "@premier/models";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ClientsTable from "../../../components/tables/ClientTable";
import { ClientsService } from "../../../lib/api/api";
import { MuiTelInput } from 'mui-tel-input';
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const EditClient: FunctionComponent = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [client, setClient] = useState<ClientDTO>();
    const [clientId, setClientId] = useState(params.clientId as string);

    const anchorEl = useRef<HTMLDivElement | null>(null);

    const [open, setOpen] = useState(false);
    const [results, setResults] = useState<any>([]);

    const getClient = async () => {
        const client = await ClientsService.getClient(clientId);

        if (client.client) setClient(client.client);
    };

    const searchAddress = async (search: string) => {
        try {
            const ret = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${search}&apiKey=90948ec8070546dfbd97de42fd11ff33`, { method: 'GET' });

            const json = await ret.json();

            const items = json.features?.map((v: any) => v.properties) ?? [];

            setResults(items);
        } catch (reason) {
            console.log(reason);
        }
    };

    useEffect(() => {
        if (clientId === 'new') {
            setClient({
                id: '',
                name: '',
                description: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                contactEmail: '',
                contactName: '',
                contactPhone: '',
                tenantId: '',
                clientNumber: 0,
                logo: ''
            });
        } else {
            getClient();
        }
    }, [clientId]);

    const setField = (field: string, v: string) => {
        setClient({
            ...client!,
            [field]: v
        });
    }

    const saveClient = async () => {
        if (clientId === 'new') {
            const newClient = await ClientsService.createClient({
                ...client!,
                logo: client!.logo ?? ''
            });

            if (newClient.client) {
                navigate(`/admin/clients/${newClient.client.id}/edit`, { replace: true })
            } else {
                console.log('error creating client');
            }
        } else {
            const updateClient = await ClientsService.updateClient(client!.id, {
                ...client!,
                logo: client!.logo ?? ''
            });

            if (updateClient.client) {
                getClient();
            } else {
                console.log('error updating client');
            }
        }
    };

    return (
        <Stack width='100%'>
            {client &&
                <Stack spacing={2}>
                    <Stack direction='row' spacing={2}>
                        <TextField
                            id="client-name"
                            label="Name"
                            variant="outlined"
                            value={client.name}
                            onChange={(e) => setField('name', e.target.value)}
                            style={{ flex: 1 }}
                        />
                        <TextField
                            id="client-id"
                            label="Client ID"
                            variant="outlined"
                            value={client.clientNumber}
                            disabled
                        />
                    </Stack>
                    <TextField
                        id="client-description"
                        label="Description"
                        variant="outlined"
                        value={client.description}
                        onChange={(e) => setField('description', e.target.value)}
                    />
                    <Stack spacing={2} direction='row' justifyContent='space-between' flexDirection='row'>
                        <TextField
                            ref={anchorEl}
                            id="client-address"
                            label="Address"
                            variant="outlined"
                            value={client.address}
                            onChange={(e) => {
                                searchAddress(e.target.value);
                                setField('address', e.target.value);
                                setOpen(true);
                            }}
                            sx={{ flex: 1 }}
                        />
                        {open &&
                            <Popper
                                placement="bottom"
                                anchorEl={anchorEl.current}
                                open={open}
                                sx={{ width: '100%', zIndex: 9999 }}
                            >
                                <Paper
                                    sx={{
                                        margin: 2,
                                        marginTop: 1,
                                        padding: 1
                                    }}
                                >
                                    <Stack maxHeight={200} overflow='auto'>
                                        <List sx={{ bgcolor: 'background.paper' }}>
                                            {results.map((v: any) => (
                                                <ListItemButton
                                                    key={v.place_id}
                                                    disableGutters
                                                    sx={{ padding: 1 }}
                                                    onClick={() => {
                                                        setClient({
                                                            ...client!,
                                                            address: v.address_line1 ?? '',
                                                            city: v.city ?? '',
                                                            state: v.state ?? '',
                                                            zip: v.postcode ?? ''
                                                        });
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <ListItemText primary={`${v.formatted}`} />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Stack>
                                </Paper>
                            </Popper>
                        }
                        <TextField
                            id="client-city"
                            label="City"
                            variant="outlined"
                            value={client.city}
                            onChange={(e) => setField('city', e.target.value)}
                            sx={{ flex: 0.5 }}
                        />
                        <TextField
                            id="client-state"
                            label="State"
                            variant="outlined"
                            value={client.state}
                            onChange={(e) => setField('state', e.target.value)}
                            sx={{ flex: 0.25 }}
                        />
                        <TextField
                            id="client-zip"
                            label="Zip"
                            variant="outlined"
                            value={client.zip}
                            onChange={(e) => setField('zip', e.target.value)}
                            sx={{ flex: 0.25 }}
                        />
                    </Stack>
                    <TextField
                        id="client-name"
                        label="Contact Name"
                        variant="outlined"
                        value={client.contactName}
                        onChange={(e) => setField('contactName', e.target.value)}
                    />
                    <Stack spacing={2} direction='row' justifyContent='space-between' flexDirection='row'>
                        <TextField
                            id="client-email"
                            label="Contact Email"
                            variant="outlined"
                            value={client.contactEmail}
                            onChange={(e) => setField('contactEmail', e.target.value)}
                            sx={{ flex: 1 }}
                        />
                        <MuiTelInput label='Contact Phone' defaultCountry="US" value={client.contactPhone} onChange={(v, i) => setField('contactPhone', v)} />
                    </Stack>
                    <Button onClick={saveClient}>
                        Save
                    </Button>
                </Stack>
            }
            {!client &&
                <>Loading</>
            }
        </Stack>
    );
}

export default EditClient;