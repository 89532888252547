import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { BuildingDTO } from '@premier/models';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as uuid from 'uuid';
import { BuildingsService } from '../../../lib/api/api/services/BuildingsService';

interface IProps {
    buildingId: string;
    isOpen: boolean;
    close: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const BuildingModal: FunctionComponent<IProps> = ({ buildingId, isOpen, close }) => {
    const params = useParams();

    const [building, setBuilding] = useState<BuildingDTO>();

    const clientId = params.clientId as string;

    useEffect(() => {
        const getBuilding = async () => {
            const building = await BuildingsService.getBuilding(buildingId);

            if (building.building) setBuilding(building.building);
        };

        if (uuid.validate(buildingId)) {
            getBuilding();
        } else {
            setBuilding({
                id: '',
                tenantId: '',
                clientId: clientId,
                name: '',
                description: '',
                Floors: []
            });
        }
    }, [buildingId]);

    const saveBuilding = async () => {
        if (uuid.validate(buildingId)) {
            const save = await BuildingsService.updateBuilding(buildingId, {name: building!.name, description: building!.description});

            if (save.error) {
                console.log('failed to save');
            }

            close();
        } else {
            const save = await BuildingsService.createBuilding({name: building!.name, description: building!.description, clientId: clientId});

            if (save.error) {
                console.log('failed to save');
            }

            close();
        }
    }

    const setField = (field: string, v: string) => {
        setBuilding({
            ...building!,
            [field]: v
        });
    }

    return (
        <Modal
            open={isOpen}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {building &&
                    <Stack spacing={2} justifyContent='center'>
                        <TextField
                            id="building-name"
                            label="Name"
                            variant="outlined"
                            value={building.name}
                            onChange={(e) => setField('name', e.target.value)}
                        />
                        <TextField
                            id="building-description"
                            label="Description"
                            variant="outlined"
                            value={building.description}
                            onChange={(e) => setField('description', e.target.value)}
                        />
                        <Button onClick={saveBuilding}>
                            Save
                        </Button>
                    </Stack>
                }
                {!building &&
                    <>Loading...</>
                }
            </Box>
        </Modal>
    );
};

export default BuildingModal;