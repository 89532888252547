import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { JobRequest, JobsResponse, JobResponse, BuildingsResponse, InspectionsResponse, InspectionResponse, BlueprintsResponse, JobDTO } from "@premier/models";

export class JobsService {
    public static async getBlueprints(id: string): Promise<BlueprintsResponse> {
        const [status, response] = await fetchApi<BlueprintsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Jobs/${id}/Blueprints`,
            requiresAuth: true
        });

        return response;
    }

    public static async getJobs(): Promise<JobsResponse> {
        const [status, response] = await fetchApi<JobsResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Jobs/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getBuildingsForJob(id: string): Promise<BuildingsResponse> {
        const [status, response] = await fetchApi<BuildingsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Jobs/${id}/Buildings`,
            requiresAuth: true
        });

        return response;
    }

    public static async createNewInspection(id: string, pastId?: string): Promise<InspectionResponse> {
        const [status, response] = await fetchApi<InspectionResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Jobs/${id}/Inspections/new`,
            requiresAuth: true,
            body: pastId ? { pastId } : {}
        });

        return response;
    }

    public static async getInspections(id: string): Promise<InspectionsResponse> {
        const [status, response] = await fetchApi<InspectionsResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Jobs/${id}/Inspections`,
            requiresAuth: true
        });

        return response;
    }

    public static async getJob(id: string): Promise<JobResponse> {
        const [status, response] = await fetchApi<JobResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Jobs/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteJob(id: string): Promise<JobResponse> {
        const [status, response] = await fetchApi<JobResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Jobs/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateJob(id: string, data: JobDTO): Promise<JobResponse> {
        const [status, response] = await fetchApi<JobResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Jobs/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createJob(data: JobDTO): Promise<JobResponse> {
        const [status, response] = await fetchApi<JobResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Jobs/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}