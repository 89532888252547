import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ClientDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ClientsTable from "../../../components/tables/ClientTable";
import { ClientsService } from "../../../lib/api/api";

const Client: FunctionComponent = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const clientId = params.clientId as string;

    const lastLocation = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    return (
            <Stack marginTop={2} direction={'row'} flex={1} spacing={clientId !== 'new' ? 2 : 0}>
                <List sx={{ bgcolor: 'background.paper', display: clientId !== 'new' ? 'block' : 'none' }}>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'edit'}
                        onClick={() => navigate('edit')}
                    >
                        <ListItemText primary='Client' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'buildings'}
                        onClick={() => navigate('buildings')}
                    >
                        <ListItemText primary='Buildings' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'blueprints'}
                        onClick={() => navigate('blueprints')}
                    >
                        <ListItemText primary='Blueprints' />
                    </ListItemButton>
                    <ListItemButton
                        disableGutters
                        sx={{ padding: 1 }}
                        selected={lastLocation === 'jobs'}
                        onClick={() => navigate('jobs')}
                    >
                        <ListItemText primary='Jobs' />
                    </ListItemButton>
                </List>
                <Outlet key={clientId} />
            </Stack>
    );
}

export default Client;