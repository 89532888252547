import { fetchApi } from "../core/FetchApi";
import { ApiSettings } from "../core/OpenApiSettings";
import { GroupRequest, GroupsResponse, GroupResponse } from "@premier/models";

export class GroupsService {
    public static async getGroups(): Promise<GroupsResponse> {
        const [status, response] = await fetchApi<GroupsResponse>(ApiSettings, {
            method: "GET",
            path: "/api/Groups/all",
            requiresAuth: true
        });

        return response;
    }

    public static async getGroup(id: string): Promise<GroupResponse> {
        const [status, response] = await fetchApi<GroupResponse>(ApiSettings, {
            method: "GET",
            path: `/api/Groups/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async deleteGroup(id: string): Promise<GroupResponse> {
        const [status, response] = await fetchApi<GroupResponse>(ApiSettings, {
            method: "DELETE",
            path: `/api/Groups/${id}`,
            requiresAuth: true
        });

        return response;
    }

    public static async updateGroup(id: string, data: GroupRequest): Promise<GroupResponse> {
        const [status, response] = await fetchApi<GroupResponse>(ApiSettings, {
            method: "PUT",
            path: `/api/Groups/${id}`,
            requiresAuth: true,
            body: data
        });

        return response;
    }

    public static async createGroup(data: GroupRequest): Promise<GroupResponse> {
        const [status, response] = await fetchApi<GroupResponse>(ApiSettings, {
            method: "POST",
            path: `/api/Groups/create`,
            requiresAuth: true,
            body: data
        });

        return response;
    }
}