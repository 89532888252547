import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { BuildingDTO, ClientDTO, QuestionCategoryGroupDTO } from "@premier/models";
import { FunctionComponent, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BuildingModal from "../../../components/modals/BuildingModal";
import QuestionGroupModal from "../../../components/modals/QuestionGroupModal";
import BuildingsTable from "../../../components/tables/BuildingTable";
import ClientsTable from "../../../components/tables/ClientTable";
import QuestionGroupsTable from "../../../components/tables/QuestionGroupsTable";
import { ClientsService, InspectionTypesService } from "../../../lib/api/api";

const QuestionGroups: FunctionComponent = () => {
    const params = useParams();

    const [groups, setGroups] = useState<QuestionCategoryGroupDTO[]>([]);
    const [editId, setEditId] = useState('');
    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const surveyId = params.surveyId as string;

    const getGroups = async () => {
        setLoading(true);
        const resp = await InspectionTypesService.getGroups(surveyId);

        if (resp) setGroups(resp);
        setLoading(false);
    };

    const onClose = () => {
        setShow(false);
        getGroups();
    }

    useEffect(() => {
        getGroups();
    }, [surveyId]);

    return (
        <Stack width='100%' spacing={2}>
            <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={() => {
                setEditId('');
                setShow(true);
            }}>
                New Group
            </Button>
            <QuestionGroupsTable loading={loading} groups={groups} refresh={getGroups} />
            {show && <QuestionGroupModal groupId={editId} isOpen={show} close={onClose} />}
        </Stack>
    );
}

export default QuestionGroups;